import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "react-tooltip/dist/react-tooltip.css";

// react-bootstrap components
import { Card, Table, Container, Row, Col } from "react-bootstrap";
import { BACKEND_API } from "webUrls";
import { toHoursAndMinutes } from "utils";
import axios from "axios";
import moment from "moment";
import DatePicker from "./Astrologer/elements/DatePicker";
import ModalComp from "./Astrologer/elements/modal";
import ReactPaginate from "react-paginate";
import DownloadWalletHistory from "./ExcelDownloads/DownloadWalletHistory";

const showStats = {
  PAYMENT_ERROR: "FAILED",
  PAYMENT_SUCCESS: "COMPLETED",
};
let itemsPerPage = 100;
let initialwalletHistory = [];
function WalletHistory() {
  let history = useHistory();

  const [walletHistory, setwalletHistory] = useState([]);
  const [value, setValue] = useState([null, null]);
  const [showSortOptions, setShowSortOptions] = useState(false);
  const [sortOptions, setSortOptions] = useState([]);
  const [selectedSort, setSelectedSort] = useState("all");
  const [filterList, setFilterList] = useState([]);
  const [openDownloadHistoryModal, setOpenDownloadHistoryModal] = useState(false);
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('')

  useEffect(() => {
    const saved = localStorage.getItem("email") || "empty";
    if (saved == "empty") {
      history.push("/login");
    } else {
      getAllCallDetails();
    }
  }, []);

  const getAllCallDetails = () => {
    axios
      .get(BACKEND_API + "api/v1/wallet/historyAdmin", {
        headers: {
          userId: localStorage.getItem("userId"),
          token: localStorage.getItem("token"),
        },
      })
      .then((response) => {
        const { transactions: data } = response.data;
        if (data && data.length > 0) {
          initialwalletHistory = data;
          setwalletHistory(data);
          setFilterList(data);
          const users = [];
          let mapper = {};
          for (let item of data) {
            if (!mapper[item.walletId]) {
              users.push(item);
              mapper[item.walletId] = 1;
            }
          }
          setSortOptions(users);
        } else {
          alert("No wallet history found!");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const filterByRange = (range) => {
    if (range && range.length > 0) {
      const startTime = new Date(range[0]).getTime();
      const endTime = new Date(range[1]).getTime();
      setStartTime(moment(range[0]).format("YYYY/MM/DD"));
      setEndTime(moment(range[1]).format("YYYY/MM/DD"));
      const filteredList = filterList.filter((item) => {
        const sessionTime = new Date(item.paymentTime).getTime();
        if (sessionTime >= startTime && sessionTime <= endTime) {
          return item;
        }
      });
      setFilterList(filteredList);
    } else {
      setFilterList(walletHistory);
    }
    setValue(range);
  };

  const sortList = (selectValue) => {
    setShowSortOptions(false);
    if (selectValue != selectedSort) {
      setSelectedSort(selectValue);
      const filterList = initialwalletHistory.filter(
        (item) => item.astrologerName.toLowerCase() == selectValue
      );
      setwalletHistory(filterList);
    } else {
      setSelectedSort(null);
      setwalletHistory(initialwalletHistory);
    }
  };
  // Pagination Content
  const [itemOffset, setItemOffset] = useState(0);
  const [pageCount, setPageCount] = useState(
    Math.ceil(filterList.length / itemsPerPage)
  );
  const endOffset = itemOffset + itemsPerPage;
  // console.log(`Loading items from ${itemOffset} to ${endOffset}`);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % filterList.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(0);
    setItemOffset(newOffset);
  };

  useEffect(() => {
    setPageCount(Math.ceil(filterList.length / itemsPerPage));
  }, [filterList]);


  return (
    <>
      <div style={{ position: "relative" }}>
        {openDownloadHistoryModal &&
          <DownloadWalletHistory
            setOpenDownloadHistoryModal={setOpenDownloadHistoryModal}
            filterList={filterList}
            startTime={startTime}
            endTime={endTime}
            setEndTime={setEndTime}
            setStartTime={setStartTime}
          />
        }
        <Container fluid>
          <Row>
            <Col md="12">
              <Card className="strpied-tabled-with-hover">
                <Card.Header className="d-sm-flex justify-content-between align-items-center">
                  <div>
                    <Card.Title as="h4">Wallet History</Card.Title>
                    <p className="card-category">Here is Wallet History</p>
                  </div>
                  <div className="my-2">
                    <p className="m-0">

                      {selectedSort !== "all"
                        ? `Wallet Balance: ${sortOptions.filter(
                          (obj) => obj.walletId == selectedSort
                        )[0]?.balance
                        }`
                        : ""}
                    </p>
                    {/* <button
                      className="rounded outline-none mb-2 border-0 py-1 bg-warning"
                      onClick={() => setShowSortOptions(true)}
                    >
                      Sort by Astrologer
                    </button> */}
                    <select
                      onChange={(e) => {
                        setSelectedSort(e.target.value);
                        setFilterList(
                          walletHistory.filter(
                            (obj) =>
                              obj.walletId == e.target.value ||
                              e.target.value == "all"
                          )
                        );
                      }}
                      value={selectedSort}
                      className="mr-2 p-1"
                    >
                      <option value="all">All</option>
                      {sortOptions.map((obj) => (
                        <option value={obj.walletId}>{obj.userName}</option>
                      ))}
                    </select>
                    <DatePicker value={value} setValue={filterByRange} />
                  </div>
                  <div>
                    <button type="button" className="btn btn-warning" onClick={() => setOpenDownloadHistoryModal(true)}>Download Excel</button>
                  </div>
                </Card.Header>
                <Card.Body className="table-full-width table-responsive px-0">
                  <Table className="table-hover table-striped">
                    <thead>
                      <tr>
                        <th className="border-0">ID</th>
                        <th className="border-0">Name</th>
                        <th className="border-0">Email</th>
                        <th className="border-0">Phone</th>
                        <th className="border-0">Date</th>
                        <th className="border-0">Amount</th>
                        <th className="border-0">Action</th>
                      </tr>
                    </thead>
                    {filterList.length > 0
                      ? filterList.map((wallet, index) => {
                        if (
                          wallet.walletId == selectedSort ||
                          selectedSort === "all"
                        )
                          if (index >= itemOffset && index < endOffset)
                            return (
                              <tbody>
                                <tr key={`chat${index}`}>
                                  <td>{index + 1}</td>
                                  <td>{wallet.userName}</td>
                                  <td>{wallet.email}</td>
                                  <td>{wallet.phone}</td>
                                  <td>
                                    {moment(wallet.paymentTime).format("LLL")}
                                  </td>
                                  <td>{wallet.price}</td>

                                  <td>
                                    {showStats[wallet.paymentStatus]
                                      ? showStats[wallet.paymentStatus]
                                      : wallet.paymentStatus}
                                  </td>
                                </tr>
                              </tbody>
                            );
                      })
                      : null}
                  </Table>
                </Card.Body>
              </Card>
              <ReactPaginate
                breakLabel="..."
                nextLabel="next >"
                className="pagination"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="< previous"
                renderOnZeroPageCount={null}
              />
            </Col>
          </Row>
          {/* <ModalComp
            show={showSortOptions}
            handleClose={() => setShowSortOptions(false)}
            title={
              <div
                className="d-flex align-items-center justify-content-center w-100"
                style={{ borderBottom: "1px solid grey" }}
              >
                <i
                  className="fa fa-filter mr-1"
                  style={{ fontSize: "20px" }}
                ></i>
                Sort By Astrologer
              </div>
            }
          >
            {sortOptions && sortOptions.length > 0 ? (
              <div className="px-2">
                {sortOptions.map((item) => {
                  const name = item.toLowerCase();
                  return (
                    <div
                      className="d-flex align-items-center py-1"
                      onClick={() => sortList(name)}
                    >
                      <input
                        type="checkbox"
                        id="switch-chat"
                        className="toggle-checkbox mb-1"
                        name={name}
                        checked={selectedSort == name}
                        style={{ height: "18px", width: "18px" }}
                        readOnly
                      />
                      <label
                        for="switch"
                        className="toggle-btn ml-3"
                        style={{ fontSize: "20px", fontWeight: "400" }}
                      >
                        {item}{" "}
                      </label>
                    </div>
                  );
                })}
              </div>
            ) : null}
          </ModalComp> */}
        </Container>
      </div >
    </>
  );
}

export default WalletHistory;
