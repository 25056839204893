import React, { useState } from 'react'
import { toHoursAndMinutes } from "utils";
import moment from "moment";
import DatePicker from "../Astrologer/elements/DatePicker";
import ExcelJS from 'exceljs';

const DownloadChatHistory = ({ chatlist, setChatlist, selectedSort, setOpenChatHistoryModal }) => {

    const [value, setValue] = useState([null, null]);


    const exportToExcel = async () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Chat History');

        const headRow = worksheet.addRow([
            "ID",
            "Astrologer Name",
            "User Name",
            "Chat Duration",
            "Chat Date",
            "Astrologer Earned",
        ]);

        headRow.font = { bold: true };
        headRow.alignment = { horizontal: 'center', vertical: 'middle' };
        worksheet.getColumn(2).width = 18;
        worksheet.getColumn(3).width = 18;
        worksheet.getColumn(4).width = 18;
        worksheet.getColumn(5).width = 18;
        worksheet.getColumn(6).width = 18;
        headRow.eachCell((cell) => {
            adjustColumnWidth(worksheet, cell);
        });

        let indexNo = 1;
        chatlist.map((user, index) => {
            const messages = user.messages;
            const content =
                messages.length > 0 ? messages[0].content : {};
            const userDetails =
                content && Object.keys(content).length > 0
                    ? content.userDetails
                    : {};
            const userName =
                userDetails && Object.keys(userDetails).length > 0
                    ? userDetails.name
                    : "--";
            const bodyRow = worksheet.addRow([
                indexNo++,
                user.astrologerName,
                userName,
                toHoursAndMinutes(user.sessionTime),
                moment(user.startTime).format("LLL"),
                user.earned,
            ])
            bodyRow.alignment = { horizontal: 'center', vertical: 'middle' };
            bodyRow.eachCell((cell) => {
                adjustColumnWidth(worksheet, cell);
            });
        })


        const blob = await workbook.xlsx.writeBuffer();
        const blobUrl = URL.createObjectURL(new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));

        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = "Chat History"
        document.body.appendChild(link);
        link.click();

        URL.revokeObjectURL(blobUrl);
    }
    const adjustColumnWidth = (worksheet, cell) => {
        const colNumber = cell.col;
        const desiredWidth = Math.max(cell.value.toString().length, 15);
        const currentWidth = worksheet.getColumn(colNumber).width || 15;

        if (desiredWidth > currentWidth) {
            worksheet.getColumn(colNumber).width = desiredWidth;
        }
    };
    const filterByRange = (range) => {
        if (range && range.length > 0) {
            const startTime = new Date(range[0]).getTime();
            const endTime = new Date(range[1]).getTime();
            const filterList = chatlist.filter(item => {
                const sessionTime = new Date(item.startTime).getTime();
                if (sessionTime >= startTime && sessionTime <= endTime) {
                    return item;
                }
            })
            setChatlist(filterList);
        } else {
            setChatlist(chatlist);
        }
        setValue(range);
    }


    const handleCloseChatModal = () => {
        setOpenChatHistoryModal(false);
    }
    return (
        <div className="modal-main-div">
            <div className="modal-container">
                <div className="wallet-modal-header d-flex p-2 align-items-center justify-content-between gap-4 header-bg">
                    <h4 className="m-0">Astrologer Chat History</h4>
                    <div className="date d-flex gap-3 align-items-center">
                        <h4 className="m-0">Astrologer Name</h4>
                        <p className="m-0 text-lg-center">
                            {selectedSort}
                        </p>
                    </div>
                    <DatePicker value={value} setValue={filterByRange} />
                    <button type="submit" class="btn btn-success" onClick={() => exportToExcel()}>Download Excel</button>
                    <button className="border-0 cursor-pointer" onClick={() => handleCloseChatModal()} style={{ fontSize: '2rem', background: 'none' }}>
                        &times;
                    </button>
                </div>
                <div className="table-container ">
                    <table className="excel-table">
                        <thead>
                            <tr className='table-heading'>
                                <th className="border-0">ID</th>
                                <th className="border-0">Astrologer Name</th>
                                <th className="border-0">User Name</th>
                                <th className="border-0">Duration</th>
                                <th className="border-0">Date</th>
                                <th className="border-0">Earned</th>
                            </tr>
                        </thead>
                        {chatlist.length > 0 ? (
                            <tbody>
                                {chatlist.map((user, index) => {
                                    const messages = user.messages;
                                    const content =
                                        messages.length > 0 ? messages[0].content : {};
                                    const userDetails =
                                        content && Object.keys(content).length > 0
                                            ? content.userDetails
                                            : {};
                                    const userName =
                                        userDetails && Object.keys(userDetails).length > 0
                                            ? userDetails.name
                                            : "--";

                                    return (
                                        <tr
                                            key={`chat${index}`}
                                            className='table-data'
                                        >
                                            <td>{index + 1}</td>
                                            <td>{user.astrologerName}</td>
                                            <td>{userName}</td>
                                            <td>{toHoursAndMinutes(user.sessionTime)}</td>
                                            <td>{moment(user.startTime).format("LLL")}</td>
                                            <td>{`₹${user.earned}`}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        ) : null}
                    </table>
                </div>
            </div>
        </div>
    )
}

export default DownloadChatHistory
