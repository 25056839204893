import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { BACKEND_API } from "webUrls";

function CategoryList() {
  let history = useHistory();

  const [userList, setUserList] = useState([]);

  useEffect(() => {
    const saved = localStorage.getItem("email") || "empty";
    if (saved == "empty") {
      history.push("/login");
    } else {
      getAllCategory();
    }
  }, []);

  const getAllCategory = () => {
    var payload = new URLSearchParams();
    payload.append("userId", localStorage.getItem("userId"));

    fetch(BACKEND_API + "course/getAllCategory", {
      method: "POST",
      body: payload,
      rejectUnauthorized: false,
      headers: {
        userId: localStorage.getItem("userId"),
        token: localStorage.getItem("token"),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          setUserList(data.data);
        } else {
          alert(data.message);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const deleteCategory = (id) => {
    const confirmBox = window.confirm(
      "Do you really want to delete this Course?"
    );
    if (confirmBox === true) {
      var payload = new URLSearchParams();
      payload.append("categoryId", id);
      fetch(BACKEND_API + "course/deleteCategory", {
        method: "POST",
        body: payload,
        rejectUnauthorized: false,
        headers: {
          userId: localStorage.getItem("userId"),
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status) {
            getAllCategory();
          } else {
            alert(data.message);
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  };

  const [formValues, setFormValues] = useState({
    categoryName: "",
  });

  const onChange = (e) => {
    setFormValues({ ...formValues, [e.target.id]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    document.getElementById("addCategoryForm").reset();
    var payload = new URLSearchParams();
    payload.append("categoryName", formValues.categoryName);

    await fetch(BACKEND_API + "course/addCategory", {
      method: "POST",
      body: payload,
      rejectUnauthorized: false,
      headers: {
        userId: localStorage.getItem("userId"),
        token: localStorage.getItem("token"),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          getAllCategory();
        } else {
          alert(data.message);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  return (
    <>
      <div style={{ position: "relative" }}>
        <Container fluid>
          <Row>
            <Col md="12">
              <Form
                id="addCategoryForm"
                onSubmit={onSubmit}
                style={{ marginBottom: "20px" }}
              >
                <Row>
                  <Col className="pr-1" md="3">
                    <Form.Group>
                      <label>Name</label>
                      <Form.Control
                        defaultValue={formValues?.categoryName}
                        placeholder="Category Name"
                        type="text"
                        id="categoryName"
                        onChange={onChange}
                        required
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col className="pr-1" md="3">
                    <br></br>
                    <Button
                      className="btn-fill pull-right"
                      type="submit"
                      variant="info"
                      style={{ padding: "0.375rem 0.75rem" }}
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>

            <Col md="12">
              <Card className="strpied-tabled-with-hover">
                <Card.Header>
                  <Card.Title as="h4">All Category List</Card.Title>
                </Card.Header>
                <Card.Body className="table-full-width table-responsive px-0">
                  <Table className="table-hover table-striped">
                    <thead>
                      <tr>
                        <th className="border-0">ID</th>
                        <th className="border-0">Name</th>
                        <th className="border-0">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {userList.map((user, index) => {
                        return (
                          <tr key={user.intId}>
                            <td>{index + 1}</td>
                            <td>{user.categoryName}</td>
                            <td>
                              <ReactTooltip anchorId="my-element11" />
                              <i
                                id="my-element11"
                                data-tooltip-content="Delete Category"
                                onClick={(e) => deleteCategory(user.intId)}
                                aria-hidden="true"
                                style={{ color: "red" }}
                                className="fa fa-trash"
                              ></i>
                              {/* <button type="button" onClick={(e) => deleteCategory(user.intId)} className="btn btn-danger btn-xs">Delete Category</button> */}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default CategoryList;
