import axios from "axios";
import { BACKEND_API } from "webUrls";

export const getBookedSlots = () => {
  return axios.post(
    BACKEND_API + "api/v1/appointment" + "/getBookedSlotsAstrologer",
    {},
    {
      headers: {
        token: localStorage.getItem("token"),
        userId: localStorage.getItem("userId"),
      },
    }
  );
};

export const rescheduleSlot = (payload) => {
  return axios.post(
    BACKEND_API + "api/v1/appointment" + "/reschedule",
    payload,
    {
      headers: {
        token: localStorage.getItem("token"),
        userId: localStorage.getItem("userId"),
      },
    }
  );
};
