import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "react-tooltip/dist/react-tooltip.css";

// react-bootstrap components
import { Card, Table, Container, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import { BACKEND_API } from "webUrls";
import { toHoursAndMinutes } from "utils";
import axios from "axios";
import moment from "moment";
import Chat from "./Chat";
import DatePicker from "./elements/DatePicker";
import ModalComp from "./elements/modal";
import DownloadChatHistory from "views/ExcelDownloads/DownloadChatHistory";

let initialChatlist = [];
function ChatHistory() {
  let history = useHistory();

  const [chatlist, setChatlist] = useState([]);
  const [showChat, setShowChat] = useState(false);
  const [selectedChat, setSelectedChat] = useState([]);
  const [value, setValue] = useState([null, null]);
  const [showSortOptions, setShowSortOptions] = useState(false);
  const [sortOptions, setSortOptions] = useState([]);
  const [selectedSort, setSelectedSort] = useState(null);
  const [openChatHistoryModal, setOpenChatHistoryModal] = useState(false);


  useEffect(() => {
    const saved = localStorage.getItem("email") || "empty";
    if (saved == "empty") {
      history.push("/login");
    } else {
      getAllChatDetails();
    }
  }, []);

  const getAllChatDetails = () => {
    axios
      .get(BACKEND_API + "api/v1/astrologer/chatHistoryAdmin")
      .then((response) => {
        const { data } = response.data;
        if (data && data.length > 0) {
          initialChatlist = data;
          setChatlist(data);
          const astrologer = [];
          for (let item of data) {
            if (!astrologer.includes(item.astrologerName)) {
              astrologer.push(item.astrologerName);
            }
          }
          setSortOptions(astrologer)
        } else {
          alert("No chat history found");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  const closeChat = () => {
    setShowChat(false);
  };
  const selectChat = (msgList) => {
    if (msgList && msgList.length > 0) {
      const content = msgList[0].content;
      if (
        typeof content == "object" &&
        content &&
        Object.keys(content).length > 0
      ) {
        setSelectedChat(msgList);
        setShowChat(true);
      } else {
        toast.error("No chat found!", { autoClose: 3000 });
      }
    } else {
      toast.error("No chat found!", { autoClose: 3000 });
    }
  };

  const filterByRange = (range) => {
    if (range && range.length > 0) {
      const startTime = new Date(range[0]).getTime();
      const endTime = new Date(range[1]).getTime();
      const filterList = initialChatlist.filter(item => {
        const sessionTime = new Date(item.startTime).getTime();
        if (sessionTime >= startTime && sessionTime <= endTime) {
          return item;
        }
      })
      setChatlist(filterList);
    } else {
      setChatlist(initialChatlist);
    }
    setValue(range);
  }

  const sortList = (selectValue) => {
    setShowSortOptions(false);
    if (selectValue != selectedSort) {
      setSelectedSort(selectValue);
      const filterList = initialChatlist.filter(item => item.astrologerName.toLowerCase() == selectValue);
      setChatlist(filterList);
    } else {
      setSelectedSort(null);
      setChatlist(initialChatlist);
    }
  }

  const downloadChatHistoryExcel = () => {
    if (selectedSort === null) {
      alert("Please select an astrologer for download chat history!")
    } else {
      setOpenChatHistoryModal(true)
    }
  }

  return (
    <>
      <div style={{ position: "relative" }}>
        <Container fluid>
          {!showChat ? (
            <Row>
              <Col md="12">
                {openChatHistoryModal && <DownloadChatHistory
                  selectedSort={selectedSort}
                  setOpenChatHistoryModal={setOpenChatHistoryModal}
                  chatlist={chatlist}
                  setChatlist={setChatlist}
                />}
                <Card className="strpied-tabled-with-hover">
                  <Card.Header className="d-sm-flex justify-content-between align-items-center">
                    <div>
                      <Card.Title as="h4">All Chat List</Card.Title>
                      <p className="card-category">Here is Chat History</p>
                    </div>
                    <div className="my-2">
                      <button className="rounded outline-none mb-2 border-0 py-1 bg-warning" onClick={() => setShowSortOptions(true)}>Select by Astrologer</button>
                      <DatePicker value={value} setValue={filterByRange} />
                    </div>
                    <div>
                      <button type="button" className={`btn btn-warning cursor-pointer`} onClick={() => downloadChatHistoryExcel()}>Download Chat History by Astrologer</button>
                    </div>
                  </Card.Header>
                  <Card.Body className="table-full-width table-responsive px-0">
                    <Table className="table-hover table-striped">
                      <thead>
                        <tr>
                          <th className="border-0">ID</th>
                          <th className="border-0">Astrologer Name</th>
                          <th className="border-0">User Name</th>
                          <th className="border-0">Duration</th>
                          <th className="border-0">Date</th>
                          <th className="border-0">Earned</th>
                          <th className="border-0">Action</th>
                        </tr>
                      </thead>
                      {chatlist.length > 0 ? (
                        <tbody>
                          {chatlist.map((user, index) => {
                            const messages = user.messages;
                            const content =
                              messages.length > 0 ? messages[0].content : {};
                            const userDetails =
                              content && Object.keys(content).length > 0
                                ? content.userDetails
                                : {};
                            const userName =
                              userDetails && Object.keys(userDetails).length > 0
                                ? userDetails.name
                                : "--";

                            return (
                              <tr
                                key={`chat${index}`}
                              >
                                <td>{index + 1}</td>
                                <td>{user.astrologerName}</td>
                                <td>{userName}</td>
                                <td>{toHoursAndMinutes(user.sessionTime)}</td>
                                <td>{moment(user.startTime).format("LLL")}</td>
                                <td>{`₹${user.earned}`}</td>
                                <td onClick={() => selectChat(messages)}><i className='fa fa-eye' style={{ cursor: 'pointer' }}></i></td>
                              </tr>
                            );
                          })}
                        </tbody>
                      ) : null}
                    </Table>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          ) : (
            <Chat messages={selectedChat} closeChat={closeChat} />
          )}
          <ModalComp
            show={showSortOptions}
            handleClose={() => setShowSortOptions(false)}
            title={<div
              className="d-flex align-items-center justify-content-center w-100"
              style={{ borderBottom: '1px solid grey' }}
            >
              <i className="fa fa-filter mr-1" style={{ fontSize: "20px" }}></i>
              Sort By Astrologer
            </div>}
          >
            {sortOptions && sortOptions.length > 0 ? <div className="px-2">
              {sortOptions.map((item) => {
                const name = item.toLowerCase();
                return (
                  <div
                    className="d-flex align-items-center py-1"
                    onClick={() =>
                      sortList(name)
                    }
                  >
                    <input
                      type="checkbox"
                      id="switch-chat"
                      className="toggle-checkbox mb-1"
                      name={name}
                      checked={selectedSort == name}
                      style={{ height: "18px", width: "18px" }}
                      readOnly
                    />
                    <label
                      for="switch"
                      className="toggle-btn ml-3"
                      style={{ fontSize: "20px", fontWeight: "400" }}
                    >
                      {item}{" "}
                    </label>
                  </div>
                );
              })}
            </div> : null}
          </ModalComp>
        </Container>
      </div>
    </>
  );
}

export default ChatHistory;
