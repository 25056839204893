import { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';

function ModalComp({show,handleClose,children,title}) {

    useEffect(()=>{
     const element = document.querySelector('.modal-dialog-centered');
     if(element){
        element.style.transform = 'translate(0, 0)'
     }
    },[show])
  return (
    <Modal centered show={show} onHide={handleClose}>
    <Modal.Header>
      <Modal.Title className='mt-0 text-center w-100' style={{fontSize:"24px",fontWeight:'400'}}>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body className='pt-0'>{children}</Modal.Body>
  </Modal>
  );
}

export default ModalComp;