import React, { useState } from 'react'
import { toHoursAndMinutes } from "utils";
import moment from "moment";
import DatePicker from "../Astrologer/elements/DatePicker";
import ExcelJS from 'exceljs';

const DownloadCallHistory = ({ chatlist, setChatlist, selectedSort, setOpenCallHistoryModal }) => {
    const [value, setValue] = useState([null, null]);


    const exportToExcel = async () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Call History');

        const headRow = worksheet.addRow([
            "ID",
            "Astrologer Name",
            "User Name",
            "Call Duration",
            "Call Date",
            "Astrologer Earned",
            "Call Status"
        ]);

        headRow.font = { bold: true };
        headRow.alignment = { horizontal: 'center', vertical: 'middle' };
        worksheet.getColumn(2).width = 18;
        worksheet.getColumn(3).width = 18;
        worksheet.getColumn(4).width = 18;
        worksheet.getColumn(5).width = 18;
        worksheet.getColumn(6).width = 30;
        headRow.eachCell((cell) => {
            adjustColumnWidth(worksheet, cell);
        });

        let indexNo = 1;
        chatlist.map((user, index) => {
            const info = user.callInfo;
            let link = "";
            let userName = "";
            if (info && Object.keys(info).length > 0) {
                link = info.recording;
                userName =
                    info.callLog && Object.keys(info.callLog)
                        ? info.callLog.answered_agent_name
                        : "";
            }

            const bodyRow = worksheet.addRow([
                indexNo++,
                user.astrologerName,
                info.details?.name,
                toHoursAndMinutes(user.time),
                moment(user.startTime).format("LLL"),
                user.earned,
                `Astrologer: ${info.callLog["_ld"][0]?.["_ac"]
                } ${info.callLog["_ld"][1]?.["_ac"]
                    ? `User: ${info.callLog["_ld"][1]["_ac"]}`
                    : ""
                }`
            ])
            bodyRow.alignment = { horizontal: 'center', vertical: 'middle' };
            bodyRow.eachCell((cell) => {
                adjustColumnWidth(worksheet, cell);
            });
        })


        const blob = await workbook.xlsx.writeBuffer();
        const blobUrl = URL.createObjectURL(new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));

        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = "Call History"
        document.body.appendChild(link);
        link.click();

        URL.revokeObjectURL(blobUrl);
    }
    const adjustColumnWidth = (worksheet, cell) => {
        const colNumber = cell.col;
        const desiredWidth = Math.max(cell.value.toString().length, 15);
        const currentWidth = worksheet.getColumn(colNumber).width || 15;

        if (desiredWidth > currentWidth) {
            worksheet.getColumn(colNumber).width = desiredWidth;
        }
    };
    const filterByRange = (range) => {
        if (range && range.length > 0) {
            const startTime = new Date(range[0]).getTime();
            const endTime = new Date(range[1]).getTime();
            const filterList = chatlist.filter(item => {
                const sessionTime = new Date(item.startTime).getTime();
                if (sessionTime >= startTime && sessionTime <= endTime) {
                    return item;
                }
            })
            setChatlist(filterList);
        } else {
            setChatlist(chatlist);
        }
        setValue(range);
    }


    const handleCloseCallModal = () => {
        setOpenCallHistoryModal(false);
    }
    return (
        <div className="modal-main-div">
            <div className="modal-container">
                <div className="wallet-modal-header d-flex p-2 align-items-center justify-content-between gap-4 header-bg">
                    <h4 className="m-0">Astrologer Call History</h4>
                    <div className="date d-flex gap-3 align-items-center">
                        <h4 className="m-0">Astrologer Name</h4>
                        <p className="m-0 text-lg-center">
                            {selectedSort}
                        </p>
                    </div>
                    <DatePicker value={value} setValue={filterByRange} />
                    <button type="submit" class="btn btn-success" onClick={() => exportToExcel()}>Download Excel</button>
                    <button className="border-0 cursor-pointer" onClick={() => handleCloseCallModal()} style={{ fontSize: '2rem', background: 'none' }}>
                        &times;
                    </button>
                </div>
                <div className="table-container ">
                    <table className="excel-table">
                        <thead>
                            <tr className='table-heading'>
                                <th className="border-0">ID</th>
                                <th className="border-0">Astrologer Name</th>
                                <th className="border-0">User Name</th>
                                <th className="border-0">Duration</th>
                                <th className="border-0">Date</th>
                                <th className="border-0">Earned</th>
                                <th className="border-0">Call Status</th>
                            </tr>
                        </thead>
                        {chatlist.length > 0 ? (
                            <tbody>
                                {chatlist.map((user, index) => {
                                    const info = user.callInfo;
                                    let link = "";
                                    let userName = "";
                                    if (info && Object.keys(info).length > 0) {
                                        link = info.recording;
                                        userName =
                                            info.callLog && Object.keys(info.callLog)
                                                ? info.callLog.answered_agent_name
                                                : "";
                                    }

                                    return (
                                        <tr key={`chat${index}`} className="table-data">
                                            <td>{index + 1}</td>
                                            <td>{user.astrologerName}</td>
                                            <td>{info.details?.name}</td>
                                            <td>{toHoursAndMinutes(user.time)}</td>
                                            <td>{moment(user.startTime).format("LLL")}</td>
                                            <td>{`₹${user.earned}`}</td>
                                            <td>{`Astrologer: ${info.callLog["_ld"][0]?.["_ac"]
                                                } ${info.callLog["_ld"][1]?.["_ac"]
                                                    ? `User: ${info.callLog["_ld"][1]["_ac"]}`
                                                    : ""
                                                }`}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        ) : null}
                    </table>
                </div>
            </div>
        </div>
    )
}

export default DownloadCallHistory
