import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "react-tooltip/dist/react-tooltip.css";

// react-bootstrap components
import { Card, Table, Container, Row, Col } from "react-bootstrap";
import { BACKEND_API } from "webUrls";
import { toHoursAndMinutes } from "utils";
import axios from "axios";
import moment from "moment";
import DatePicker from "./elements/DatePicker";
import ModalComp from "./elements/modal";
import DownloadCallHistory from "views/ExcelDownloads/DownloadCallHistory";

let initialChatlist = [];
function CallHistory() {
  let history = useHistory();

  const [chatlist, setChatlist] = useState([]);
  const [value, setValue] = useState([null, null]);
  const [showSortOptions, setShowSortOptions] = useState(false);
  const [sortOptions, setSortOptions] = useState([]);
  const [selectedSort, setSelectedSort] = useState(null);
  const [openCallHistoryModal, setOpenCallHistoryModal] = useState(false)

  useEffect(() => {
    const saved = localStorage.getItem("email") || "empty";
    if (saved == "empty") {
      history.push("/login");
    } else {
      getAllCallDetails();
    }
  }, []);

  const getAllCallDetails = () => {
    axios
      .get(BACKEND_API + "api/v1/call/callHistoryAdmin", {
        headers: {
          userId: localStorage.getItem("userId"),
          token: localStorage.getItem("token"),
        },
      })
      .then((response) => {
        const { data } = response.data;
        if (data && data.length > 0) {
          initialChatlist = data;
          setChatlist(data);
          const astrologer = [];
          for (let item of data) {
            if (!astrologer.includes(item.astrologerName)) {
              astrologer.push(item.astrologerName);
            }
          }
          setSortOptions(astrologer);
        } else {
          alert("No chat history found");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const filterByRange = (range) => {
    if (range && range.length > 0) {
      const startTime = new Date(range[0]).getTime();
      const endTime = new Date(range[1]).getTime();
      const filterList = initialChatlist.filter((item) => {
        const sessionTime = new Date(item.startTime).getTime();
        if (sessionTime >= startTime && sessionTime <= endTime) {
          return item;
        }
      });
      setChatlist(filterList);
    } else {
      setChatlist(initialChatlist);
    }
    setValue(range);
  };

  const sortList = (selectValue) => {
    setShowSortOptions(false);
    if (selectValue != selectedSort) {
      setSelectedSort(selectValue);
      const filterList = initialChatlist.filter(
        (item) => item.astrologerName.toLowerCase() == selectValue
      );
      setChatlist(filterList);
    } else {
      setSelectedSort(null);
      setChatlist(initialChatlist);
    }
  };

  const downloadCallHistoryExcel = () => {
    if (selectedSort === null) {
      alert("Please select an astrologer for download call history!")
    } else {
      setOpenCallHistoryModal(true)
    }
  }

  return (
    <>
      <div style={{ position: "relative" }}>
        <Container fluid>
          <Row>
            <Col md="12">
              {openCallHistoryModal && <DownloadCallHistory
                selectedSort={selectedSort}
                setOpenCallHistoryModal={setOpenCallHistoryModal}
                chatlist={chatlist}
                setChatlist={setChatlist}
              />}
              <Card className="strpied-tabled-with-hover">
                <Card.Header className="d-sm-flex justify-content-between align-items-center">
                  <div>
                    <Card.Title as="h4">All Call List</Card.Title>
                    <p className="card-category">Here is Call History</p>
                  </div>
                  <div className="my-2">
                    <button
                      className="rounded outline-none mb-2 border-0 py-1 bg-warning"
                      onClick={() => setShowSortOptions(true)}
                    >
                      Sort by Astrologer
                    </button>
                    <DatePicker value={value} setValue={filterByRange} />
                  </div>
                  <div>
                    <button type="button" className={`btn btn-warning cursor-pointer`} onClick={() => downloadCallHistoryExcel()}>Download Call History by Astrologer</button>
                  </div>
                </Card.Header>
                <Card.Body className="table-full-width table-responsive px-0">
                  <Table className="table-hover table-striped">
                    <thead>
                      <tr>
                        <th className="border-0">ID</th>
                        <th className="border-0">Astrologer Name</th>
                        <th className="border-0">User Name</th>
                        <th className="border-0">Duration</th>
                        <th className="border-0">Date</th>
                        <th className="border-0">Earned</th>
                        <th className="border-0">Call Status</th>
                        <th className="border-0">Action</th>
                      </tr>
                    </thead>
                    {chatlist.length > 0 ? (
                      <tbody>
                        {chatlist.map((user, index) => {
                          const info = user.callInfo;
                          let link = "";
                          let userName = "";
                          if (info && Object.keys(info).length > 0) {
                            link = info.recording;
                            userName =
                              info.callLog && Object.keys(info.callLog)
                                ? info.callLog.answered_agent_name
                                : "";
                          }

                          return (
                            <tr key={`chat${index}`}>
                              <td>{index + 1}</td>
                              <td>{user.astrologerName}</td>
                              <td>{info.details?.name}</td>
                              <td>{toHoursAndMinutes(user.time)}</td>
                              <td>{moment(user.startTime).format("LLL")}</td>
                              <td>{`₹${user.earned}`}</td>
                              <td>{`Astrologer: ${info.callLog["_ld"][0]?.["_ac"]
                                } ${info.callLog["_ld"][1]?.["_ac"]
                                  ? `User: ${info.callLog["_ld"][1]["_ac"]}`
                                  : ""
                                }`}</td>
                              <td onClick={() => window.open(link, "_blank")}>
                                <i
                                  className="fa fa-eye"
                                  style={{ cursor: "pointer" }}
                                ></i>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    ) : null}
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <ModalComp
            show={showSortOptions}
            handleClose={() => setShowSortOptions(false)}
            title={
              <div
                className="d-flex align-items-center justify-content-center w-100"
                style={{ borderBottom: "1px solid grey" }}
              >
                <i
                  className="fa fa-filter mr-1"
                  style={{ fontSize: "20px" }}
                ></i>
                Sort By Astrologer
              </div>
            }
          >
            {sortOptions && sortOptions.length > 0 ? (
              <div className="px-2">
                {sortOptions.map((item) => {
                  const name = item.toLowerCase();
                  return (
                    <div
                      className="d-flex align-items-center py-1"
                      onClick={() => sortList(name)}
                    >
                      <input
                        type="checkbox"
                        id="switch-chat"
                        className="toggle-checkbox mb-1"
                        name={name}
                        checked={selectedSort == name}
                        style={{ height: "18px", width: "18px" }}
                        readOnly
                      />
                      <label
                        for="switch"
                        className="toggle-btn ml-3"
                        style={{ fontSize: "20px", fontWeight: "400" }}
                      >
                        {item}{" "}
                      </label>
                    </div>
                  );
                })}
              </div>
            ) : null}
          </ModalComp>
        </Container>
      </div>
    </>
  );
}

export default CallHistory;
