import React, { useState, useEffect, Component } from "react";
import { useHistory } from "react-router-dom";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import YouTube from "react-youtube";
import ReactPaginate from "react-paginate";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import moment from "moment";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Form,
  Container,
  Row,
  Col,
  Modal,
} from "react-bootstrap";
import { BACKEND_API } from "webUrls";
import { getAstrologers } from "api/Astrologer";
import { deleteAstrologer } from "api/Astrologer";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { getAstrologersBooking } from "api/Astrologer";
import { getBookedSlots } from "api/Appointment";
import axios from "axios";
import { rescheduleSlot } from "api/Appointment";

function Items({ currentItems }) {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({});

  const handleClose = () => setShow(false);
  const handleShow = (obj) => {
    if (!obj.is_reschedulable) return alert("this call is not reschedulable");
    setFormData({
      appointmentSlotId: obj.appointment_slot_id,
      oldJobId: obj.scheduler_job_id,
      newStartTime: obj.startTime,
      newEndTime: obj.endTime,
      newAppointmentDate: moment(obj.appointment_date).format("YYYY-MM-DD"),
      bookingId: obj.id,
      userId: obj.user_id,
      astrologerId: obj.astrologer_id,
    });
    setShow(true);
  };
  const FormSubmit = (e) => {
    e.preventDefault();
    reschedule();
  };
  const reschedule = async () => {
    try {
      let payload = formData;
      await rescheduleSlot(payload);
      window.location.reload();
    } catch (err) {
      console.log(err);
      alert("Something Went Wrong!");
    }
    setFormData({});
    handleClose();
  };
  const onChangeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <>
      <Table className="table-hover table-striped">
        <thead>
          <tr>
            <th className="border-0">ID</th>
            <th className="border-0">Astrologer Name</th>
            <th className="border-0">User Name</th>
            <th className="border-0">User Email</th>
            <th className="border-0">User Mobile No.</th>
            <th className="border-0">User Birth</th>
            <th className="border-0">Appointment Date</th>
            <th className="border-0">Start Time</th>
            <th className="border-0">End Time</th>
            {/* <th className="border-0">Course Description</th> */}
          </tr>
        </thead>
        <tbody>
          {currentItems &&
            currentItems.map((astrologer, index) => (
              <tr
                onClick={() => handleShow(astrologer)}
                key={astrologer.name}
                style={{ cursor: "pointer" }}
              >
                <td>{index + 1}</td>
                <td>{astrologer.astrologerName}</td>
                <td>{astrologer.userName}</td>
                <td>{astrologer.userEmail}</td>
                <td>{astrologer.userPhone}</td>
                <td>
                  {moment(astrologer.birth_date, "YYYY-MM-DD").format(
                    "YYYY-MM-DD"
                  )}{" "}
                  {moment(astrologer.birth_time, "h:m:s").format("hh:mm A")}{" "}
                  {astrologer.birth_place}
                </td>
                <td>
                  {" "}
                  {moment(astrologer.appointment_date).format("YYYY-MM-DD")}
                </td>
                <td>
                  {" "}
                  {astrologer.startTime
                    ? moment(astrologer.startTime, "h:m:s").format("hh:mm A")
                    : "Deleted!"}
                </td>
                <td>
                  {" "}
                  {astrologer.endTime
                    ? moment(astrologer.endTime, "h:m:s").format("hh:mm A")
                    : "Deleted!"}
                </td>
              </tr>
            ))}
        </tbody>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <p style={{ fontSize: 20, fontWeight: 500 }}>Re-Schedule</p>
          </Modal.Header>
          <Modal.Body>
            <form
              onSubmit={FormSubmit}
              className="d-flex flex-col w-full justify-content-around"
            >
              <input
                type="date"
                name="newAppointmentDate"
                value={formData.newAppointmentDate}
                onChange={onChangeHandler}
              />
              <input
                type="time"
                name="newStartTime"
                value={formData.newStartTime}
                onChange={onChangeHandler}
              />
              <input
                type="time"
                name="newEndTime"
                value={formData.newEndTime}
                onChange={onChangeHandler}
              />
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={reschedule}>
              Re-Schedule
            </Button>
          </Modal.Footer>
        </Modal>
      </Table>
    </>
  );
}

function PaginatedItems({ itemsPerPage, list }) {
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = list.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(list.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % list.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  return (
    <>
      <Items currentItems={currentItems} />
      <ReactPaginate
        breakLabel="..."
        nextLabel="next >"
        className="pagination"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="< previous"
        renderOnZeroPageCount={null}
      />
    </>
  );
}

export default function index() {
  const history = useHistory();
  const [Slots, setSlots] = useState([]);

  const load = async () => {
    try {
      let responseBooking = await getBookedSlots();
      setSlots([...responseBooking.data.data]);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    load();
  }, []);

  return (
    <div>
      {" "}
      <Row>
        <Col md="12">
          <Card className="strpied-tabled-with-hover">
            <Card.Header>
              <Card.Title as="h4">All Slots</Card.Title>
              <p className="card-category">Here is a list of all Slots</p>
            </Card.Header>
            <Card.Body className="table-full-width table-responsive px-0">
              <PaginatedItems itemsPerPage={10} list={Slots} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
