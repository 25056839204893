import React from 'react'
import './Modal.css'
import moment from "moment";
import ExcelJS from 'exceljs';


const DownloadWalletHistory = ({ setOpenDownloadHistoryModal, startTime, endTime, setStartTime, setEndTime, filterList }) => {


    const exportToExcel = async () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Wallet History');

        const headRow = worksheet.addRow([
            "ID",
            "Name",
            "Email",
            "Phone Number",
            "Date",
            "Amount",
            "Payment Status",
        ]);

        headRow.font = { bold: true };
        headRow.alignment = { horizontal: 'center', vertical: 'middle' };
        worksheet.getColumn(4).width = 18;
        worksheet.getColumn(5).width = 18;
        worksheet.getColumn(6).width = 18;
        worksheet.getColumn(7).width = 18;
        headRow.eachCell((cell, colNumber) => {
            const desiredWidth = Math.max(cell.value.toString().length, 15);
            const currentWidth = worksheet.getColumn(colNumber).width || 15;

            if (desiredWidth > currentWidth) {
                worksheet.getColumn(colNumber).width = desiredWidth;
            }
        });
        let indexNo = 1;
        filterList.map((wallet, index) => {
            const bodyRow = worksheet.addRow([
                indexNo++,
                wallet.userName,
                wallet.email,
                wallet.phone,
                moment(wallet.paymentTime).format("LLL"),
                wallet.price,
                showStats[wallet.paymentStatus]
                    ? showStats[wallet.paymentStatus]
                    : wallet.paymentStatus
            ])
            bodyRow.alignment = { horizontal: 'center', vertical: 'middle' };
            bodyRow.eachCell((cell, colNumber) => {
                const desiredWidth = Math.max(cell.value.toString().length, 15);
                const currentWidth = worksheet.getColumn(colNumber).width || 15;

                if (desiredWidth > currentWidth) {
                    worksheet.getColumn(colNumber).width = desiredWidth;
                }
            });
        })


        const blob = await workbook.xlsx.writeBuffer();
        const blobUrl = URL.createObjectURL(new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));

        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = "Wallet History Data";
        document.body.appendChild(link);
        link.click();

        URL.revokeObjectURL(blobUrl);

    }

    const handleCloseWalletHistoryModal = () => {
        setOpenDownloadHistoryModal(false);
        setEndTime('');
        setStartTime('');
    }
    const showStats = {
        PAYMENT_ERROR: "FAILED",
        PAYMENT_SUCCESS: "COMPLETED",
    };
    return (
        <div className="modal-main-div">
            <div className="modal-container">
                <div className="wallet-modal-header d-flex p-2 align-items-center justify-content-between gap-4 header-bg">
                    <h4 className="m-0">Wallet History</h4>
                    <div className="date d-flex gap-3 align-items-center">
                        <h4 className="m-0">Date</h4>
                        <p className="m-0 text-lg-center">
                            {startTime && endTime ? `${startTime} - ${endTime}` : 'Not selected!'}
                        </p>
                    </div>
                    <button type="submit" class="btn btn-success" onClick={() => exportToExcel()}>Download Excel</button>
                    <button className="border-0 cursor-pointer" onClick={() => handleCloseWalletHistoryModal()} style={{ fontSize: '2rem', background: 'none' }}>
                        &times;
                    </button>
                </div>
                <div className="table-container ">
                    <table className="excel-table">
                        <thead>
                            <tr className='table-heading'>
                                <th className="border-0">ID</th>
                                <th className="border-0">Name</th>
                                <th className="border-0">Email</th>
                                <th className="border-0">Phone</th>
                                <th className="border-0">Date</th>
                                <th className="border-0">Amount</th>
                                <th className="border-0">Action</th>
                            </tr>
                        </thead>
                        {filterList.length > 0
                            ? filterList.map((wallet, index) => {
                                return (
                                    <tbody>
                                        <tr key={`chat${index}`} className='table-data'>
                                            <td>{index + 1}</td>
                                            <td>{wallet.userName}</td>
                                            <td>{wallet.email}</td>
                                            <td>{wallet.phone}</td>
                                            <td>
                                                {moment(wallet.paymentTime).format("LLL")}
                                            </td>
                                            <td>{wallet.price}</td>

                                            <td>
                                                {showStats[wallet.paymentStatus]
                                                    ? showStats[wallet.paymentStatus]
                                                    : wallet.paymentStatus}
                                            </td>
                                        </tr>
                                    </tbody>
                                );
                            })
                            : null}
                    </table>
                </div>
            </div>
        </div>
    )
}

export default DownloadWalletHistory
