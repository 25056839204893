import React, { useState, useEffect, Component } from "react";
import { useHistory } from "react-router-dom";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import YouTube from "react-youtube";
import ReactPaginate from "react-paginate";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Form,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { BACKEND_API } from "webUrls";

function Courses() {
  let history = useHistory();
  const [isFormVisible, setisFormVisible] = useState(false);
  const [selectedValue, setSelectedValue] = useState(1);
  const [selectedLanaguageValue, setSelectedLanguageValue] = useState("");
  const [selectedCategoryValue, setSelectedCategoryValue] = useState("");
  const [editForm, setEditForm] = useState(false);
  const [editFormId, setEditFormId] = useState(0);
  const [formValues, setFormValues] = useState({
    name: "",
    fees: "",
    time: "",
    option: selectedValue,
    categoryName: selectedCategoryValue,
    language: selectedLanaguageValue,
    description: "",
    fullDescription: "",
    videos: [],
  });

  const videoLinkArray = [
    {
      type: "text",
      id: "Link1",
      value: "",
    },
  ];
  const [newVideoLink, setNewVideoLink] = useState(videoLinkArray);
  const addNewVideoLink = () => {
    setNewVideoLink((s) => {
      return [
        ...s,
        {
          type: "text",
          value: "",
        },
      ];
    });
  };

  const removeNewVideoLink = (id) => {
    let newVideosLinks = [...newVideoLink];
    newVideosLinks.splice(id, 1);
    setNewVideoLink(newVideosLinks);
  };

  const handleChange = (e) => {
    e.preventDefault();
    const index = e.target.id;
    setNewVideoLink((s) => {
      const newArr = s.slice();
      newArr[Number(index.split("Link")[1]) - 1].value = e.target.value;
      return newArr;
    });
  };

  let showForm = () => {
    setEditForm(false);
    setisFormVisible(!isFormVisible);
    setSelectedValue("");
    setSelectedLanguageValue("");
    setSelectedCategoryValue("");
    setNewVideoLink(videoLinkArray);
    setCourseFile("");
    setCourseFile1("");
    setFormValues({
      ...formValues,
      ["categoryName"]: "",
      ["name"]: "",
      ["fees"]: "",
      ["time"]: "",
      ["option"]: "",
      ["description"]: "",
      ["fullDescription"]: "",
      ["videos"]: "",
    });
  };

  const onSelectChange = (e) => {
    setSelectedValue(e.target.value);
  };
  const onLanguageSelectChange = (e) => {
    setSelectedLanguageValue(e.target.value);
  };
  const onSelectCategory = (e) => {
    setSelectedCategoryValue(e.target.value);
  };

  const [isVideoVisible, setisVideoVisible] = useState(false);
  const [selectedVideoValue, setSelectedVideoValue] = useState("");
  const openVideoPopUp = (iframe) => {
    setisVideoVisible(!isVideoVisible);
    setSelectedVideoValue(iframe);
  };

  const [courseList, setCourseList] = useState([]);
  useEffect(() => {
    const saved = localStorage.getItem("email") || "empty";
    if (saved == "empty") {
      history.push("/login");
    } else {
      getAllCategory();
      getCourseList();
    }
  }, []);

  const [categoryList, setCategoryList] = useState([]);
  const getAllCategory = () => {
    var payload = new URLSearchParams();
    payload.append("userId", localStorage.getItem("userId"));

    fetch(BACKEND_API + "course/getAllCategory", {
      method: "POST",
      body: payload,
      rejectUnauthorized: false,
      headers: {
        userId: localStorage.getItem("userId"),
        token: localStorage.getItem("token"),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          setCategoryList(data.data);
        } else {
          alert(data.message);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const [file, setFile] = useState("");
  const handleChangeImage = (e) => {
    const data = e.target.files[0];
    setFile(data);
  };

  const [file1, setFile1] = useState("");
  const handleChangeImage1 = (e) => {
    const data = e.target.files[0];
    setFile1(data);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    // console.log("selectedLanaguageValue", selectedLanaguageValue)
    // console.log("formValues", formValues)

    let videoFrames = newVideoLink.map(function (item) {
      return item["value"];
    });

    const payload = new FormData();
    payload.append("image", file == "" ? courseFile : file);
    payload.append("image1", file1 == "" ? courseFile1 : file1);
    // var payload = new URLSearchParams();
    payload.append("courseName", formValues.name);
    payload.append("courseFees", formValues.fees);
    payload.append("courseTime", formValues.time);

    payload.append("courseDescription", formValues.description);
    payload.append("courseFullDescription", formValues.fullDescription);
    // payload.append("courseIFrames", formValues.videos);
    payload.append("courseIFrames", videoFrames);
    payload.append("categoryName", selectedCategoryValue.split("-")[0]);
    payload.append("category_id", selectedCategoryValue.split("-")[1]);
    payload.append("language", selectedLanaguageValue);

    if (editForm) {
      payload.append("courseType", selectedValue);
      payload.append("courseId", editFormId);

      var url = "";
      if (
        file == "" &&
        file1 == "" &&
        typeof file == "string" &&
        typeof file1 == "string"
      ) {
        url = "upload/editCourses";
      } else if (
        file != "" &&
        typeof file == "object" &&
        file1 != "" &&
        typeof file1 == "object"
      ) {
        url = "upload/editCourseUpload";
      } else if (
        file != "" &&
        typeof file == "object" &&
        file1 == "" &&
        typeof file1 == "string"
      ) {
        url = "upload/editCoursess";
      } else {
        url = "upload/editCoursesss";
      }

      // console.log(typeof file)
      // console.log(typeof file1)
      // console.log(url)

      // var url = file == '' ? 'upload/editCourses' : 'upload/editCourseUpload'
      fetch(BACKEND_API + url, {
        method: "POST",
        body: { ...payload },
        rejectUnauthorized: false,
        headers: {
          userId: localStorage.getItem("userId"),
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status) {
            showForm();
            getCourseList();
          } else {
            alert(data.message);
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    } else {
      payload.append("courseType", selectedValue);

      // fetch(BACKEND_API + 'course/addNewCourse', {
      fetch(BACKEND_API + "upload/uploadCourse", {
        method: "POST",
        body: payload,
        rejectUnauthorized: false,
        headers: {
          userId: localStorage.getItem("userId"),
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status) {
            showForm();
            getCourseList();
          } else {
            alert(data.message);
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  };
  const onChange = (e) => {
    setFormValues({ ...formValues, [e.target.id]: e.target.value });
  };

  const getCourseList = () => {
    var payload = new URLSearchParams();
    payload.append("userId", localStorage.getItem("userId"));
    payload.append("type", "all");
    fetch(BACKEND_API + "course/getAllCourses", {
      method: "POST",
      body: payload,
      rejectUnauthorized: false,
      headers: {
        userId: localStorage.getItem("userId"),
        token: localStorage.getItem("token"),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          setCourseList(data.data);
        } else {
          alert(data.message);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const deleteCourse = (id) => {
    const confirmBox = window.confirm(
      "Do you really want to delete this Course?"
    );
    if (confirmBox === true) {
      var payload = new URLSearchParams();
      payload.append("courseId", id);
      fetch(BACKEND_API + "course/deleteCourse", {
        method: "POST",
        body: payload,
        rejectUnauthorized: false,
        headers: {
          userId: localStorage.getItem("userId"),
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status) {
            getCourseList();
          } else {
            alert(data.message);
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  };

  const [courseFile, setCourseFile] = useState("");
  const [courseFile1, setCourseFile1] = useState("");
  const editCourse = (user) => {
    showForm();
    setEditForm(true);
    setEditFormId(user.intId);
    setSelectedValue(user.courseType);
    setSelectedCategoryValue(user.categoryName);
    setSelectedLanguageValue(user.language);
    setCourseFile(user.courseImage);
    setCourseFile1(user.courseImage1);

    var myFrmaeArray = [];
    user.courseIFrames.split(",").map((frmae, index) => {
      myFrmaeArray.push({
        type: "text",
        value: frmae,
        id: "link" + (index + 1),
      });
    });
    setNewVideoLink(myFrmaeArray);
    setFormValues({
      ...formValues,
      ["categoryName"]: user.categoryName,
      ["name"]: user.courseName,
      ["fees"]: user.courseFees,
      ["time"]: user.courseTime,
      ["option"]: user.courseType,
      ["description"]: user.courseDescription,
      ["fullDescription"]: user.fullDescription,
      ["videos"]: user.courseIFrames,
    });
  };

  const goToPage = (data) => {
    localStorage.setItem("videoData", data);
    history.push({
      pathname: "/admin/viewAllVideos",
      state: {
        response: data,
      },
    });
  };
  const goToDetailPage = (data) => {
    localStorage.setItem("videoData", data);
    history.push({
      pathname: "/admin/courseDetail",
      state: {
        response: data,
      },
    });
  };

  const inputHandler = (event, editor) => {
    // console.log(event, editor.getData());
    formValues.fullDescription = editor.getData();
    setFormValues({ ...formValues, ["fullDescription"]: editor.getData() });
  };

  function Items({ currentItems }) {
    return (
      <>
        <Table className="table-hover table-striped">
          <thead>
            <tr>
              <th className="border-0">ID</th>
              <th className="border-0">Category Name</th>
              <th className="border-0">Course Name</th>
              <th className="border-0">Course Fees</th>
              <th className="border-0">Course Time</th>
              {/* <th className="border-0">Course Description</th> */}
              <th className="border-0">Course Videos</th>
              <th className="border-0">Registration Date</th>
              <th className="border-0">Action</th>
            </tr>
          </thead>
          <tbody>
            {currentItems &&
              currentItems.map((user, index) => (
                <tr key={user.courseName}>
                  <td>{index + 1}</td>
                  <td>{user.categoryName}</td>
                  <td>{user.courseName}</td>
                  <td>{user.courseFees}</td>
                  <td>{user.courseTime + " " + user.courseType}</td>
                  {/* <td>{user.courseDescription}</td> */}
                  <td>
                    {user.courseIFrames.split(",").map((iframe, kk) => {
                      if (kk < 3) {
                        return (
                          <span key={kk}>
                            <ReactTooltip anchorId={"my-element" + kk} />
                            <i
                              id={"my-element" + kk}
                              data-tooltip-content="View Video"
                              onClick={(e) => openVideoPopUp(iframe)}
                              style={{ color: "green" }}
                              className="fa fa-video iframeThum"
                            ></i>
                          </span>
                        );
                      }
                    })}
                    {user.courseIFrames.split(",").length > 3 && (
                      <>
                        <ReactTooltip anchorId={"my-elementt"} />
                        <i
                          id={"my-elementt"}
                          data-tooltip-content="View All Videos"
                          onClick={() => goToPage(user.courseIFrames)}
                          style={{ color: "blue" }}
                          className="fa fa-eye iframeThum"
                        ></i>
                      </>
                    )}
                  </td>
                  <td>{user.createdDate.split("T")[0]}</td>
                  <td>
                    <ReactTooltip anchorId="my-element12" />
                    <i
                      id="my-element12"
                      data-tooltip-content="View Detail"
                      onClick={(e) => goToDetailPage(user.intId)}
                      aria-hidden="true"
                      style={{ color: "green" }}
                      className="fa fa-eye"
                    ></i>
                    <ReactTooltip anchorId="my-element" />
                    <i
                      id="my-element"
                      data-tooltip-content="Edit Course"
                      onClick={(e) => editCourse(user)}
                      aria-hidden="true"
                      style={{ color: "orange" }}
                      className="fa fa-edit"
                    ></i>
                    <ReactTooltip anchorId="my-element11" />
                    <i
                      id="my-element11"
                      data-tooltip-content="Delete Course"
                      onClick={(e) => deleteCourse(user.intId)}
                      aria-hidden="true"
                      style={{ color: "red" }}
                      className="fa fa-trash"
                    ></i>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </>
    );
  }

  function PaginatedItems({ itemsPerPage }) {
    const [itemOffset, setItemOffset] = useState(0);
    const endOffset = itemOffset + itemsPerPage;
    // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    const currentItems = courseList.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(courseList.length / itemsPerPage);

    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % courseList.length;
      console.log(
        `User requested page number ${event.selected}, which is offset ${newOffset}`
      );
      setItemOffset(newOffset);
    };

    return (
      <>
        <Items currentItems={currentItems} />
        <ReactPaginate
          breakLabel="..."
          nextLabel="next >"
          className="pagination"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="< previous"
          renderOnZeroPageCount={null}
        />
      </>
    );
  }

  return (
    <>
      <div style={{ position: "relative" }}>
        {isVideoVisible === true ? (
          <div
            style={{
              padding: "20px",
              position: "absolute",
              zIndex: "10",
              background: "white",
              width: "100%",
              top: "0",
              left: "0",
              textAlign: "center",
            }}
          >
            <div style={{ marginBottom: "20px", display: "inline" }}>
              <ReactTooltip anchorId="my-element1" />
              <i
                id="my-element1"
                data-tooltip-content="Close Popup"
                onClick={() => openVideoPopUp()}
                aria-hidden="true"
                style={{
                  margin: "10px",
                  float: "right",
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
                className="nc-icon nc-simple-remove"
              ></i>
            </div>

            <YouTube videoId={selectedVideoValue.split("v=")[1]} />
            {/* <div style={{ textAlign: "center" }} dangerouslySetInnerHTML={{ __html: `${selectedVideoValue}` }} /> */}
          </div>
        ) : (
          <div />
        )}

        {isFormVisible === true ? (
          <div>
            <Row>
              <Col md="2"></Col>
              <Col md="8">
                <Card>
                  <Card.Header>
                    <Card.Title as="h4">
                      Add Course
                      <ReactTooltip anchorId="my-element1" />
                      <i
                        id="my-element1"
                        data-tooltip-content="Close Popup"
                        onClick={(e) => showForm()}
                        aria-hidden="true"
                        style={{
                          color: "green",
                          fontSize: "20px",
                          fontWeight: "bold",
                          float: "right",
                        }}
                        className="nc-icon nc-simple-remove"
                      ></i>
                      {/* <button type="button" onClick={() => showForm()} className="btn btn-danger" style={{ margin: "10px", float: "right" }}>Close</button> */}
                    </Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Form onSubmit={onSubmit} encType="multipart/form-data">
                      <Row>
                        <Col className="pr-1" md="4">
                          <Form.Group>
                            <label>Course Category</label>
                            <select
                              value={selectedCategoryValue}
                              onChange={onSelectCategory}
                              required
                              className="custom-select"
                            >
                              <option value="">Select Category</option>
                              {categoryList.map((user, index) => {
                                return (
                                  <option
                                    key={user.categoryName}
                                    value={`${user.categoryName}-${user.intId}`}
                                  >
                                    {user.categoryName}
                                  </option>
                                );
                              })}
                            </select>
                          </Form.Group>
                        </Col>
                        <Col className="pl-1" md="4">
                          <Form.Group>
                            <label htmlFor="exampleInputEmail1">
                              Course Name
                            </label>
                            <input
                              type="text"
                              onChange={onChange}
                              required
                              className="form-control"
                              id="name"
                              value={formValues.name}
                              aria-describedby="coursename"
                              placeholder="Enter Name"
                            />
                          </Form.Group>
                        </Col>
                        <Col className="pl-1" md="4">
                          <Form.Group>
                            <label htmlFor="exampleInputEmail1">
                              Course Fees in $
                            </label>
                            <input
                              type="number"
                              onChange={onChange}
                              required
                              className="form-control"
                              id="fees"
                              min={1}
                              value={formValues.fees}
                              aria-describedby="coursefees"
                              placeholder="Course Fees in $"
                            />
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col className="pr-1" md="4">
                          <Form.Group>
                            <label>Course Time</label>
                            <input
                              type="Number"
                              onChange={onChange}
                              required
                              className="form-control"
                              id="time"
                              min={1}
                              value={formValues.time}
                              aria-describedby="time"
                              placeholder="Enter Time For The"
                            />
                          </Form.Group>
                        </Col>
                        <Col className="pl-1" md="4">
                          <Form.Group>
                            <label htmlFor="exampleInputEmail1">
                              Course Time Type
                            </label>
                            <select
                              value={selectedValue}
                              onChange={onSelectChange}
                              required
                              className="custom-select"
                            >
                              <option value="">Select Type</option>
                              <option value="Hours">Hours</option>
                              <option value="Day">Day</option>
                              <option value="Month">Month</option>
                              <option value="Year">Year</option>
                            </select>
                          </Form.Group>
                        </Col>
                        <Col className="pl-1" md="4">
                          <Form.Group>
                            <label htmlFor="exampleInputEmail1">
                              Course Language
                            </label>
                            <select
                              value={selectedLanaguageValue}
                              onChange={onLanguageSelectChange}
                              required
                              className="custom-select"
                            >
                              <option value="">Select Langauge</option>
                              <option value="English">English</option>
                              <option value="Hindi">Hindi</option>
                              <option value="Gujrati">Gujrati</option>
                              <option value="Punjabi">Punjabi</option>
                              <option value="Tamil">Tamil</option>
                              <option value="Telugu">Telugu</option>
                            </select>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col className="pr-1" md="12">
                          <Form.Group>
                            <label htmlFor="exampleInputEmail1">
                              Course Image 1
                            </label>
                            {courseFile != "" && (
                              <input
                                type="file"
                                onChange={handleChangeImage}
                                className="form-control"
                                id="name"
                                placeholder="Enter Name"
                              />
                            )}
                            {courseFile == "" && (
                              <input
                                type="file"
                                onChange={handleChangeImage}
                                required
                                className="form-control"
                                id="name"
                                placeholder="Enter Name"
                              />
                            )}
                            {file && (
                              <div>
                                {/* <span>File Name - {file.name}</span> */}
                                {/* <br></br> */}
                                <img
                                  style={{ width: "100%" }}
                                  src={URL.createObjectURL(file)}
                                />
                              </div>
                            )}
                            {courseFile && !file && (
                              <div>
                                <img
                                  style={{ width: "100%" }}
                                  src={BACKEND_API + courseFile}
                                />
                              </div>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col className="pr-1" md="12">
                          <Form.Group>
                            <label htmlFor="exampleInputEmail1">
                              Course Image 2
                            </label>
                            {courseFile1 != "" && (
                              <input
                                type="file"
                                onChange={handleChangeImage1}
                                className="form-control"
                                id="name1"
                                placeholder="Enter Name"
                              />
                            )}
                            {courseFile1 == "" && (
                              <input
                                type="file"
                                onChange={handleChangeImage1}
                                className="form-control"
                                id="name1"
                                placeholder="Enter Name"
                              />
                            )}
                            {file1 && (
                              <div>
                                {/* <span>File Name - {file.name}</span> */}
                                {/* <br></br> */}
                                <img
                                  style={{ width: "100%" }}
                                  src={URL.createObjectURL(file1)}
                                />
                              </div>
                            )}
                            {courseFile1 && !file1 && (
                              <div>
                                <img
                                  style={{ width: "100%" }}
                                  src={BACKEND_API + courseFile1}
                                />
                              </div>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        {/* <Col className="pl-1" md="6">
                          <Form.Group>
                            <label htmlFor="exampleInputEmail1">Course Videos</label>
                            <textarea className="form-control" required onChange={onChange} id="videos" rows="3" value={formValues.videos}></textarea>
                          </Form.Group>
                        </Col> */}

                        {newVideoLink.map((item, i) => {
                          return (
                            <>
                              <Col
                                className=""
                                md="5"
                                style={{ paddingLeft: "15px" }}
                              >
                                <Form.Group>
                                  <label>Links - {i + 1}</label>
                                  <input
                                    required
                                    className="form-control"
                                    onChange={handleChange}
                                    value={item.value}
                                    id={"Link" + (i + 1)}
                                    placeholder={"Link " + (i + 1)}
                                    type={item.type}
                                  />
                                </Form.Group>
                              </Col>
                              {i == 0 && <Col className="" md="1"></Col>}
                              {i != 0 && (
                                <Col
                                  className=""
                                  md="1"
                                  style={{ marginTop: "15px" }}
                                >
                                  <ReactTooltip anchorId={"my-element"} />
                                  <label></label>
                                  <i
                                    id={"my-element"}
                                    data-tooltip-content="Delete Link"
                                    onClick={() => removeNewVideoLink(i)}
                                    style={{ color: "red", marginTop: "35px" }}
                                    className="fa fa-trash"
                                  ></i>
                                </Col>
                              )}
                            </>
                          );
                        })}
                      </Row>
                      <button
                        type="button"
                        onClick={addNewVideoLink}
                        className="btn btn-primary btn-xs"
                      >
                        Add New Video
                      </button>

                      <Row>
                        <Col className="pr-1" md="12">
                          <Form.Group>
                            <label>Course Small Description</label>
                            <textarea
                              className="form-control"
                              required
                              onChange={onChange}
                              id="description"
                              rows="3"
                              value={formValues.description}
                            ></textarea>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-1" md="12">
                          <Form.Group>
                            <label>Course Full Description</label>
                            {/* <textarea className="form-control" required onChange={onChange} id="fullDescription" rows="3" value={formValues.description}></textarea> */}
                            <CKEditor
                              id="inputText"
                              editor={ClassicEditor}
                              onChange={inputHandler}
                              data={formValues.fullDescription}
                              value={formValues.fullDescription}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      {editFormId > 0 && (
                        <button type="submit" className="btn btn-primary">
                          Update
                        </button>
                      )}
                      {editFormId <= 0 && (
                        <button type="submit" className="btn btn-primary">
                          Submit
                        </button>
                      )}
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
              <Col md="2"></Col>
            </Row>
          </div>
        ) : (
          <div />
        )}

        {!isFormVisible && !isVideoVisible && (
          <Container fluid>
            <button
              type="button"
              onClick={() => showForm()}
              className="btn btn-primary"
              style={{ margin: "10px" }}
            >
              Add Course
            </button>

            <Row>
              <Col md="12">
                <Card className="strpied-tabled-with-hover">
                  <Card.Header>
                    <Card.Title as="h4">All Courses</Card.Title>
                    <p className="card-category">
                      Here is a list of all Courses
                    </p>
                  </Card.Header>
                  <Card.Body className="table-full-width table-responsive px-0">
                    <PaginatedItems itemsPerPage={10} />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        )}
      </div>
    </>
  );
}

export default Courses;
