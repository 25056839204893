import React, { useState, useEffect, Component } from "react";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";

// react-bootstrap components
import { Card, Form, Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getCampaigns, createCampaign } from "api/Astrologer";
import { editCampain } from "api/Astrologer";

export default function CreateForm() {
  const { id } = useParams();
  const [state, setState] = useState({
    name: "",
    price: "",
    url: "",
    time: "",
  });

  const onSubmit = async (e) => {
    try {
      e.preventDefault();
      const { name, price, url, time } = state;
      let body = {
        startTime: time,
        title: name,
        amount: Number(price),
        whatsappGroupUrl: url,
      };
      if (id) {
        body = { ...body, id: id };
      }

      let response = {};
      if (id) response = await editCampain(body);
      else response = await createCampaign(body);

      history.back();
    } catch (err) {
      console.log(err);
    }
  };

  const onChangeHandler = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const load = async () => {
    try {
      if (id) {
        let response = await getCampaigns();
        const list = response.data.campaigns;

        const filterList = list.filter((item) => item.id == id);
        if (filterList.length == 0) {
          history.back();
        } else {
          const { title, whatsappGroupUrl, amount, startTime } = filterList[0];
          console.log(startTime);
          setState({
            ...state,
            name: title,
            url: whatsappGroupUrl,
            price: amount,
            time: startTime.split("T").join(" ").split("Z")[0],
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const close = () => {
    history.back();
  };

  useEffect(() => {
    load();
  }, []);
  return (
    <div>
      <Row>
        <Col md="2"></Col>
        <Col md="8">
          <Card>
            <Card.Header>
              <Card.Title as="h4">
                Add Campaign
                <ReactTooltip anchorId="my-element1" />
                <i
                  id="my-element1"
                  data-tooltip-content="Close Popup"
                  onClick={close}
                  aria-hidden="true"
                  style={{
                    color: "green",
                    fontSize: "20px",
                    fontWeight: "bold",
                    float: "right",
                  }}
                  className="nc-icon nc-simple-remove"
                ></i>
                {/* <button type="button" onClick={() => showForm()} className="btn btn-danger" style={{ margin: "10px", float: "right" }}>Close</button> */}
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Form onSubmit={onSubmit} encType="multipart/form-data">
                <Row>
                  <Col className="pl-1" md="6">
                    <Form.Group>
                      <label htmlFor="exampleInputEmail1">Title</label>
                      <input
                        type="text"
                        onChange={onChangeHandler}
                        value={state.name}
                        name="name"
                        required
                        className="form-control"
                        id="name"
                        aria-describedby="coursename"
                        placeholder="Enter Title"
                      />
                    </Form.Group>
                  </Col>
                  <Col className="pl-1" md="6">
                    <Form.Group>
                      <label htmlFor="exampleInputEmail1">Amount</label>
                      <input
                        type="number"
                        name="price"
                        value={state.price}
                        onChange={onChangeHandler}
                        required
                        className="form-control"
                        id="price"
                        min={1}
                        aria-describedby="price"
                        placeholder="Amount"
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col className="pr-1">
                    <Form.Group>
                      <label htmlFor="email">Whatsapp Group Url</label>
                      <input
                        type="text"
                        name="url"
                        value={state.url}
                        onChange={onChangeHandler}
                        required
                        className="form-control"
                        aria-describedby="email"
                        placeholder="Enter Whatsapp Group Url"
                      />
                    </Form.Group>
                  </Col>
                  <Col className="pl-1">
                    <Form.Group>
                      <label htmlFor="password">StartTime</label>
                      <input
                        type="datetime-local"
                        value={state.time}
                        name="time"
                        onChange={onChangeHandler}
                        required
                        className="form-control"
                        id="password"
                        aria-describedby="password"
                        placeholder="Enter Time"
                      />
                    </Form.Group>
                  </Col>
                </Row>

                {id && (
                  <button type="submit" className="btn btn-primary mt-3">
                    Update
                  </button>
                )}
                {!id && (
                  <button type="submit" className="btn btn-primary mt-3">
                    Submit
                  </button>
                )}
              </Form>
            </Card.Body>
          </Card>
        </Col>
        <Col md="2"></Col>
      </Row>
    </div>
  );
}
