/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from 'views/Dashboard.js';
import UserProfile from 'views/UserProfile.js';
import TableList from 'views/TableList.js';
import Typography from 'views/Typography.js';
import Icons from 'views/Icons.js';
import Maps from 'views/Maps.js';
import Notifications from 'views/Notifications.js';
import Upgrade from 'views/Upgrade.js';
import UserList from 'views/UserList';
import Courses from 'views/Courses';
import UserCourses from 'views/UserCourses';
import UserPayments from 'views/UserPayments';
import Workshop from 'views/Workshop';
import CategoryList from 'views/CategoryList';
import ViewAllVideo from 'views/viewAllVideo';
import Slider from 'views/Slider';
import Panchang from 'views/Panchang';
import PanchangDetail from 'views/panchangDetail';
import Reviews from 'views/Reviews';
import BirthDetail from 'views/BirthDetail';
import MatchDetail from 'views/MatchDetail';
import DownloadBirthDetail from 'views/DownloadBirthDetail';
import DownloadMatchDetail from 'views/DownloadMatchDetail';
import Blog from 'views/Blog';
import Subscribers from 'views/Subscribers';
import CourseVideos from 'views/CourseVideos';
import Astrologer from 'views/Astrologer';
import Category from 'views/Category';
import CategoryForm from 'views/Category/CreateForm';
import CreateForm from 'views/Astrologer/CreateForm';
import Campaign from 'views/Campaign';
import CreateFormCampaign from 'views/Campaign/CreteForm';
import ChatHistory from 'views/Astrologer/ChatHistory';
import CallHistory from 'views/Astrologer/CallHistory';
import CampaignUsers from 'views/CampaignUsers';
import WalletHistory from 'views/WalletHistory';
import BookedSlots from 'views/BookedSlot';
import RequestLogs from 'views/RequestLogs';
import Visitor from 'views/Visitor';
import HoroscopeBlog from 'views/HoroscopeBlogs';

const dashboardRoutes = [
  // {
  //   upgrade: true,
  //   path: "/upgrade",
  //   name: "Upgrade to PRO",
  //   icon: "nc-icon nc-alien-33",
  //   component: Upgrade,
  //   layout: "/admin"
  // },
  // CATEGORY ROUTES
  {
    path: '/bookedSlots',
    name: 'Booked Slots',
    icon: 'nc-icon nc-align-center',
    component: BookedSlots,
    layout: '/admin',
    visible: true,
  },
  {
    path: '/walletHistory',
    name: 'Wallet History',
    icon: 'nc-icon nc-align-center',
    component: WalletHistory,
    layout: '/admin',
    visible: true,
  },
  {
    path: '/categories',
    name: 'astro categories',
    icon: 'nc-icon nc-align-center',
    component: Category,
    layout: '/admin',
    visible: true,
  },

  {
    path: '/categoryForm/:id',
    icon: 'nc-icon nc-circle-09',
    component: CategoryForm,
    layout: '/admin',
    visible: false,
  },
  {
    path: '/categoryForm',
    icon: 'nc-icon nc-circle-09',
    component: CategoryForm,
    layout: '/admin',
    visible: false,
  },
  {
    path: '/astrologer',
    name: 'Astrologer',
    icon: 'nc-icon nc-circle-09',
    component: Astrologer,
    layout: '/admin',
    visible: true,
  },
  {
    path: '/astrologerForm/:id',
    icon: 'nc-icon nc-circle-09',
    component: CreateForm,
    layout: '/admin',
    visible: false,
  },
  // Chat And Call Histort Route
  {
    path: '/chat-history',
    name: 'Chat History',
    icon: 'nc-icon nc-chat-round',
    component: ChatHistory,
    layout: '/admin',
    visible: true,
  },
  {
    path: '/call-history',
    name: 'Call History',
    icon: 'nc-icon nc-mobile',
    component: CallHistory,
    layout: '/admin',
    visible: true,
  },
  {
    path: '/requestLogs',
    name: 'Request Logs',
    icon: 'nc-icon nc-grid-45',
    component: RequestLogs,
    layout: '/admin',
    visible: true,
  },
  {
    path: '/astrologerForm',
    icon: 'nc-icon nc-circle-09',
    component: CreateForm,
    layout: '/admin',
    visible: false,
  },
  {
    path: '/campaign',
    name: 'Campaign',
    icon: 'nc-icon nc-album-2',
    component: Campaign,
    layout: '/admin',
    visible: true,
  },
  {
    path: '/usersCampaign',
    name: 'Campaign Users',
    icon: 'nc-icon nc-circle-09',
    component: CampaignUsers,
    layout: '/admin',
    visible: true,
  },
  {
    path: '/campaignForm/:id',
    icon: 'nc-icon nc-circle-09',
    component: CreateFormCampaign,
    layout: '/admin',
    visible: false,
  },
  {
    path: '/campaignForm',
    icon: 'nc-icon nc-circle-09',
    component: CreateFormCampaign,
    layout: '/admin',
    visible: false,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'nc-icon nc-chart-pie-35',
    component: Dashboard,
    layout: '/admin',
    visible: true,
  },
  {
    path: '/profile',
    name: 'Admin Profile',
    icon: 'nc-icon nc-settings-90',
    component: UserProfile,
    layout: '/admin',
    visible: true,
  },
  {
    path: '/category',
    name: 'Category',
    icon: 'nc-icon nc-align-center',
    component: CategoryList,
    layout: '/admin',
    visible: true,
  },
  {
    path: '/userlist',
    name: 'Register Users List',
    icon: 'nc-icon nc-circle-09',
    component: UserList,
    layout: '/admin',
    visible: true,
  },
  {
    path: '/usercourse',
    name: 'Paid Users Courses',
    icon: 'nc-icon nc-badge',
    component: UserCourses,
    layout: '/admin',
    visible: true,
  },
  {
    path: '/userpayement',
    name: 'Users Payments',
    icon: 'nc-icon nc-notes',
    component: UserPayments,
    layout: '/admin',
    visible: true,
  },
  {
    path: '/workshop',
    name: 'Workshop',
    icon: 'nc-icon nc-notes',
    component: Workshop,
    layout: '/admin',
    visible: true,
  },
  {
    path: '/visitors',
    name: 'Visitor',
    icon: 'nc-icon nc-notes',
    component: Visitor,
    layout: '/admin',
    visible: true,
  },
  {
    path: '/courses',
    name: 'Courses',
    icon: 'nc-icon nc-grid-45',
    component: Courses,
    layout: '/admin',
    visible: true,
  },
  {
    path: '/courseVideos',
    name: 'Course Video',
    icon: 'nc-icon nc-grid-45',
    component: CourseVideos,
    layout: '/admin',
    visible: true,
  },
  {
    path: '/blog',
    name: 'Blog',
    icon: 'nc-icon nc-grid-45',
    component: Blog,
    layout: '/admin',
    visible: true,
  },
  {
    path: '/horoscope-blog',
    name: 'HoroScope Blog',
    icon: 'nc-icon nc-grid-45',
    component: HoroscopeBlog,
    layout: '/admin',
    visible: true,
  },
  {
    path: '/slider',
    name: 'Slider',
    icon: 'nc-icon nc-grid-45',
    component: Slider,
    layout: '/admin',
    visible: true,
  },
  {
    path: '/birthDetail',
    name: 'Birth Detail',
    icon: 'nc-icon nc-grid-45',
    component: BirthDetail,
    layout: '/admin',
    visible: true,
  },
  {
    path: '/downloadBirthDetail',
    name: 'Download Birth',
    icon: 'nc-icon nc-grid-45',
    component: DownloadBirthDetail,
    layout: '/admin',
    visible: true,
  },
  {
    path: '/matchDetail',
    name: 'Match Detail',
    icon: 'nc-icon nc-grid-45',
    component: MatchDetail,
    layout: '/admin',
    visible: true,
  },
  {
    path: '/downloadMacthDetail',
    name: 'Download Macth',
    icon: 'nc-icon nc-grid-45',
    component: DownloadMatchDetail,
    layout: '/admin',
    visible: true,
  },
  {
    path: '/review',
    name: 'Reviews',
    icon: 'nc-icon nc-grid-45',
    component: Reviews,
    layout: '/admin',
    visible: true,
  },
  {
    path: '/subscribers',
    name: 'Subscribers',
    icon: 'nc-icon nc-grid-45',
    component: Subscribers,
    layout: '/admin',
    visible: true,
  },
  {
    path: '/panchang',
    name: 'Panchang',
    icon: 'nc-icon nc-grid-45',
    component: Panchang,
    layout: '/admin',
    visible: true,
  },
  {
    path: '/viewAllVideos',
    name: 'View All Videos',
    icon: 'nc-icon nc-grid-45',
    component: ViewAllVideo,
    layout: '/admin',
    visible: false,
  },
  {
    path: '/panchangDetail',
    name: 'Panchang Detail',
    icon: 'nc-icon nc-grid-45',
    component: PanchangDetail,
    layout: '/admin',
    visible: false,
  },
];

export default dashboardRoutes;
