import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col
} from "react-bootstrap";
import { BACKEND_API } from 'webUrls';

function User() {
  let history = useHistory();
  const [formValues, setFormValues] = useState({
    email: "",
    name: "",
    phone: "",
  });


  const [formValuesForPass, setFormValuesForPass] = useState({
    current: "",
    newPass: "",
    repeatPass: "",
  });


  useEffect(() => {
    const saved = localStorage.getItem("email") || "empty";
    if (saved == "empty") {
      history.push("/login");
    } else {
      getMyProfile()
    }
  }, []);


  const getMyProfile = async () => {
    var payload = new URLSearchParams();
    payload.append("userId", localStorage.getItem('userId'));
    await fetch(BACKEND_API + 'user/getUserProfile', {
      method: 'POST',
      body: payload,
      rejectUnauthorized: false,
      headers: {
        'userId': localStorage.getItem('userId'),
        'token': localStorage.getItem('token'),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          setFormValues({ ...formValues, ['email']: data['data'][0].email, ['name']: data['data'][0].name, ['phone']: data['data'][0].phone });
        } else {
          alert(data.message)
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  const onChange = (e) => {
    setFormValues({ ...formValues, [e.target.id]: e.target.value });
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    var payload = new URLSearchParams();
    payload.append("userId", localStorage.getItem('userId'));
    payload.append("name", formValues.name);
    payload.append("email", formValues.email);
    payload.append("phone", formValues.phone);

    await fetch(BACKEND_API + 'user/editUserProfile', {
      method: 'POST',
      body: payload,
      rejectUnauthorized: false,
      headers: {
        'userId': localStorage.getItem('userId'),
        'token': localStorage.getItem('token'),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          getMyProfile()
        } else {
          alert(data.message)
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  }



  const onChangePass = (e) => {
    setFormValuesForPass({ ...formValuesForPass, [e.target.id]: e.target.value });
  }

  const onSubmit1 = async (e) => {
    e.preventDefault();
    var payload = new URLSearchParams();
    payload.append("userId", localStorage.getItem('userId'));
    payload.append("currentPassword", formValuesForPass.cpassword);
    payload.append("newPassword", formValuesForPass.npassword);
    payload.append("repeatPassword", formValuesForPass.rpassword);

    await fetch(BACKEND_API + 'user/changePassword', {
      method: 'POST',
      body: payload,
      rejectUnauthorized: false,
      headers: {
        'userId': localStorage.getItem('userId'),
        'token': localStorage.getItem('token'),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          document.getElementById("changePasswordForm").reset();
          setFormValues({ ...formValuesForPass, ['cpassword']: '', ['npassword']: '', ['rpassword']: '' });
          getMyProfile()
        } else {
          alert(data.message)
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="6">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Edit Profile</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form onSubmit={onSubmit}>
                  <Row>
                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label>Name</label>
                        <Form.Control
                          defaultValue={formValues?.name}
                          placeholder="Username"
                          type="text"
                          id='name'
                          onChange={onChange}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="pl-1" md="6">
                      <Form.Group>
                        <label htmlFor="exampleInputEmail1">
                          Phone
                        </label>
                        <Form.Control
                          defaultValue={formValues?.phone}
                          placeholder="Email"
                          disabled
                          type="text"
                          id='phone'
                          onChange={onChange}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="pr-1" md="12">
                      <Form.Group>
                        <label>Email</label>
                        <Form.Control
                          defaultValue={formValues?.email}
                          disabled
                          placeholder="Company"
                          type="email"
                          id='email'
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <br></br>
                  <Button
                    className="btn-fill pull-right"
                    type="submit"
                    variant="info"
                  >
                    Update Profile
                  </Button>
                  <div className="clearfix"></div>
                </Form>
              </Card.Body>
            </Card>
          </Col>





          <Col md="6">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Change Password</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form id='changePasswordForm' onSubmit={onSubmit1}>
                  <Row>
                    <Col className="pr-1" md="12">
                      <Form.Group>
                        <label>Current Password</label>
                        <Form.Control
                          placeholder="Current Password"
                          type="password"
                          id='cpassword'
                          required
                          onChange={onChangePass}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label>New Password</label>
                        <Form.Control
                          placeholder="New Password"
                          type="password"
                          id='npassword'
                          required
                          onChange={onChangePass}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="pl-1" md="6">
                      <Form.Group>
                        <label htmlFor="exampleInputEmail1">
                          Repeat Password
                        </label>
                        <Form.Control
                          placeholder="Repeat Password"
                          type="password"
                          id='rpassword'
                          required
                          onChange={onChangePass}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>


                  <br></br>
                  <Button
                    className="btn-fill pull-right"
                    type="submit"
                    variant="info"
                  >
                    Change Password
                  </Button>
                  <div className="clearfix"></div>
                </Form>
              </Card.Body>
            </Card>
          </Col>





          {/* <Col md="4">
            <Card className="card-user">
              <div className="card-image">
                <img
                  alt="..."
                  src={require("assets/img/photo-1431578500526-4d9613015464.jpeg")}
                ></img>
              </div>
              <Card.Body>
                <div className="author">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img
                      alt="..."
                      className="avatar border-gray"
                      src={require("assets/img/faces/face-3.jpg")}
                    ></img>
                    <h5 className="title">Mike Andrew</h5>
                  </a>
                  <p className="description">michael24</p>
                </div>
                <p className="description text-center">
                  "Lamborghini Mercy <br></br>
                  Your chick she so thirsty <br></br>
                  I'm in that two seat Lambo"
                </p>
              </Card.Body>
              <hr></hr>
              <div className="button-container mr-auto ml-auto">
                <Button
                  className="btn-simple btn-icon"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  variant="link"
                >
                  <i className="fab fa-facebook-square"></i>
                </Button>
                <Button
                  className="btn-simple btn-icon"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  variant="link"
                >
                  <i className="fab fa-twitter"></i>
                </Button>
                <Button
                  className="btn-simple btn-icon"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  variant="link"
                >
                  <i className="fab fa-google-plus-square"></i>
                </Button>
              </div>
            </Card>
          </Col> */}
        </Row>
      </Container>
    </>
  );
}

export default User;
