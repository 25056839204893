import React from "react";

import "./styles/chat.css";
import moment from "moment";

export default function Chat({ messages, closeChat }) {
  
  return (
    <div
      className="App w-100 h-100 d-flex"
      style={{ minHeight: "100vh", flexFlow: "column" }}
    >
      <div className="w-100">
        <div
          className="w-100 d-flex justify-content-between align-items-center"
          style={{
            backgroundColor: "rgb(238, 235, 230)",
            borderBottom: "0.10px solid #797C7B",
          }}
        >
          <div className="d-flex align-items-center px-2">
            <div
              className="rounded-circle d-flex justify-content-center align-items-center mr-2"
              style={{
                height: "35px",
                width: "35px",
                backgroundColor: "grey",
              }}
            >
              <i className="fa fa-user" style={{ color: "#F2F7FB" }}></i>
            </div>
            <div style={{ marginTop: "15px" }}>
              <h1
                style={{
                  lineHeight: "17px",
                  fontSize: "16px",
                  fontWeight: "bold",
                  margin: "0"
                }}
              >
                {messages.length > 0
                  ? messages[0].content ?
                    messages[0].content.userDetails ?
                      messages[0].content.userDetails.name :
                      null
                    : null
                  : null
                }
              </h1>
              <p style={{ color: "grey", fontSize: "10px" }}>User</p>
            </div>
          </div>
          <button
            className=" p-1 px-3 text-white bg-danger"
            style={{ borderRadius: "7px", outline: "none",height:"35px",border:"none" }}
            onClick={() => closeChat()}
          >
            Close
          </button>
        </div>
      </div>

      <div
        className="w-100"
        style={{ flex: "1 1 auto",height:"500px", backgroundColor: "rgb(238, 235, 230)",overflow:"scroll" }}
      >
        {messages && messages.length > 0
          ? messages.map((message, index) => {
            return (
              <div
                className={`chat-msg ${message.sendBy === "ASTROLOGER"
                  ? "ml-auto mr-0 pl-3"
                  : "ml-0 mr-auto pr-3"
                  }`}
                style={{
                  color:
                    message.sendBy === "ASTROLOGER" ? "#626363" : "#F2F7FB",
                  marginTop: "5px",
                  marginBottom:
                    index == messages.length - 1 ? "80px " : "5px",
                }}
                key={index}
              >
                <div
                  className={`px-2 py-1 mx-2 mt-3`}
                  style={{
                    borderRadius: "7px",
                    backgroundColor:
                      message.sendBy !== "ASTROLOGER"
                        ? "grey"
                        : "white",
                    borderTopLeftRadius:
                      message.sendBy === "ASTROLOGER" ? "7px" : "0px",
                    borderTopRightRadius:
                      message.sendBy === "ASTROLOGER" ? "0px" : "7px",
                  }}
                >
                  {(message.isDetails) ? (
                    <>
                      Hi, below are my details<br></br>
                      Name: {message.content.userDetails ? message.content.userDetails.name : '--'}
                      <br></br>
                      Gender: {message.content.userDetails ? message.content.userDetails.gender : '--'}
                      <br></br>
                      DOB: {message.content.userDetails ? message.content.userDetails.date : '--'}
                      <br></br>
                      Birth Time: {message.content.userDetails ? message.content.userDetails.time : '--'}
                      <br></br>
                      Address: {message.content.userDetails ? message.content.userDetails.address : '--'}
                      <br></br>
                    </>
                  ) : (
                    message.content
                  )}
                </div>
                <p
                  className={`text-end float-right m-0`}
                  style={{ color: "grey", fontSize: "10px", paddingRight: message.sendBy === "ASTROLOGER"? "10px":"0px",paddingLeft: message.sendBy === "ASTROLOGER"? "0px":"10px" }}
                >
                  {moment(message.timeStamp).format("LT")}
                </p>
              </div>
            );
          })
          : null}
      </div>
    </div>
  );
}
