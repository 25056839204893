import React, { useState, useEffect, Component } from "react";
import { useHistory } from "react-router-dom";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import ReactPaginate from "react-paginate";

// react-bootstrap components
import { Card, Table, Row, Col } from "react-bootstrap";
import { getCampaigns, deleteCampaigns } from "api/Astrologer";
import { getCampaignUsers } from "api/Astrologer";

function Items({ currentItems }) {
  const history = useHistory();
  const deleteAction = async (id) => {
    try {
      await deleteCampaigns(id);

      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  const openEdit = (id) => {
    history.push("/admin/campaignForm/" + id);
  };

  return (
    <>
      <Table className="table-hover table-striped">
        <thead>
          <tr>
            <th className="border-0">ID</th>
            <th className="border-0">Name</th>
            <th className="border-0">Email</th>
            <th className="border-0">phone</th>
            <th className="border-0">Location</th>
            <th className="border-0">Status</th>
          </tr>
        </thead>
        <tbody>
          {currentItems &&
            currentItems.map((campagin, index) => (
              <tr key={campagin.id}>
                <td>{index + 1}</td>
                <td>{campagin.userName}</td>
                <td>{campagin.userEmail}</td>
                <td>{campagin.phone}</td>
                <td>{campagin.location}</td>
                <td>{campagin.paymentStatus}</td>
              </tr>
            ))}
        </tbody>
      </Table>
    </>
  );
}

function PaginatedItems({ itemsPerPage, list }) {
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = list.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(list.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % list.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  return (
    <>
      <Items currentItems={currentItems} />
      <ReactPaginate
        breakLabel="..."
        nextLabel="next >"
        className="pagination"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="< previous"
        renderOnZeroPageCount={null}
      />
    </>
  );
}

export default function CampaignUsers() {
  const history = useHistory();
  const [campaignUsers, setCampaigns] = useState([]);
  const [campaign, setCampaign] = useState([]);
  const [selectedId, setSelectedId] = useState("");

  const openCreate = () => {
    history.push("/admin/campaignForm");
  };

  const load = async () => {
    try {
      let response = await getCampaignUsers();
      setCampaigns(response.data.data);

      let campaignResponse = await getCampaigns();
      setCampaign(campaignResponse.data.campaigns);

      if (campaignResponse.data.campaigns.length > 0)
        setSelectedId(parseInt(campaignResponse.data.campaigns[0].id));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    load();
  }, []);

  return (
    <div>
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        {" "}
        {/* <button
          type="button"
          onClick={() => openCreate()}
          className="btn btn-primary"
          style={{ margin: "10px" }}
        >
          Add Campaign
        </button> */}
        <select
          className="mb-2"
          style={{ padding: "11px" }}
          onChange={(e) => setSelectedId(parseInt(e.target.value))}
        >
          {campaign.map((obj) => {
            return (
              <option style={{ padding: "11px" }} value={obj.id}>
                {obj.title}
              </option>
            );
          })}
        </select>
      </div>
      <Row>
        <Col md="12">
          <Card className="strpied-tabled-with-hover">
            <Card.Header>
              <Card.Title as="h4">All Campaign Users</Card.Title>
              <p className="card-category">Here is a list of all campaigns</p>
            </Card.Header>
            <Card.Body className="table-full-width table-responsive px-0">
              <PaginatedItems
                itemsPerPage={10}
                list={campaignUsers.filter((obj) =>
                  selectedId === "" || !selectedId
                    ? true
                    : obj.campaignId === selectedId
                )}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
