import { getAstrologers } from "api/Astrologer";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { Card, Col, Container, Row, Table, Modal } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import LoadingIndicator from "react-loading-indicator";
import { BACKEND_API } from "webUrls";
import "react-datepicker/dist/react-datepicker.css";
import ReactPaginate from "react-paginate";

const status = {
  1: "Requested by user",
  2: "Request cancelled by user",
  3: "Request declined by astrologer",
  4: "Request accepted by astrologer",
  5: "Request declined by user",
  6: "Request accepted by user",
  7: "Call not connected at astrologer",
  8: "Call connected at astrologer",
  9: "Call rejected by astrologer",
  10: "Call connected at user",
  11: "Call not answered by user",
  12: "Call rejected by user",
  13: "No response by user",
  14: "Reminding user",
  15: "Reminding astrologer",
};

function Items({ currentItems, loading }) {
  const [show, setShow] = useState(false);
  const [logsDetails, setLogDetails] = useState([]);

  const getSingleLog = (id) => {
    axios
      .request({
        method: "POST",
        url: `${BACKEND_API}api/v1/requests/logs`,
        headers: {
          userId: localStorage.getItem("userId"),
          token: localStorage.getItem("token"),
        },
        data: {
          incomingRequestId: id,
        },
      })
      .then((response) => {
        handleShow();
        setLogDetails(response.data.data);
      })
      .catch((error) => {
        alert("Something went wrong!");
      });
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Table className="table-hover table-striped">
        <thead>
          {loading ? (
            <tr>
              <td align="center">
                <LoadingIndicator />
              </td>
            </tr>
          ) : (
            <tr>
              <th className="border-0">ID</th>
              <th className="border-0">USER NAME</th>
              <th className="border-0">ASTROLOGER NAME</th>
              <th className="border-0">TIMESTAMP</th>
              <th className="border-0">REQUEST TYPE</th>
              <th className="border-0">CONNECTED</th>
              <th className="border-0">ACTION</th>
            </tr>
          )}
        </thead>
        <tbody>
          {currentItems.length == 0 ? (
            <tr>
              <td align="center" colSpan={7}>
                <p className="card-category">No Data Found</p>
              </td>
            </tr>
          ) : (
            currentItems.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item.userDetails.userDetails.name}</td>
                <td>{item.astrologerName}</td>
                <td>{moment(item.timestamp).format("DD MMM HH:mm:ss A")}</td>
                <td>
                  {String(item.type).charAt(0).toUpperCase() +
                    String(item.type).slice(1)}
                </td>
                <td>{String(item.connected).toUpperCase()}</td>
                <td>
                  {" "}
                  <i
                    id="my-element12"
                    data-tooltip-content="View Log Details"
                    aria-hidden="true"
                    style={{ color: "black" }}
                    onClick={() => getSingleLog(item.id)}
                    className="fa fa-eye"
                  ></i>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title
            className="mt-0 text-center w-100"
            style={{ fontSize: "24px", fontWeight: "400" }}
          >
            Log Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table className="table-striped">
            <thead>
              <tr>
                <th>ID</th>
                <th>REQUEST STATUS</th>
                <th>ENTRY TIME</th>
              </tr>
            </thead>
            <tbody>
              {logsDetails.length == 0 && (
                <tr>
                  <td colSpan={3} align="center">
                    No Data Found
                  </td>
                </tr>
              )}
              {logsDetails.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{status[item.incoming_request_status_id]}</td>
                    <td>{moment(item.entrytime).format("HH:mm:ss A")}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </>
  );
}

function PaginatedItems({ itemsPerPage, list, loading }) {
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = list.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(list.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % list.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      <Items currentItems={currentItems} loading={loading} />
      {list.length > 12 && (
        <ReactPaginate
          breakLabel="..."
          nextLabel="Next >"
          previousLabel="< Previous"
          className="pagination"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          renderOnZeroPageCount={null}
        />
      )}
    </>
  );
}

export default function RequestLogs() {
  const [loading, setLoading] = useState(true);
  const [incomingLog, setIncomingLog] = useState([]);
  const [incomingLogFilterList, setIncomingLogFilterList] = useState([]);

  const [astrologerName, setAstroLogerName] = useState("all");
  const [astrologerList, setAstrologerList] = useState([]);

  const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));

  const filterLogs = (value) => {
    setIncomingLogFilterList(
      incomingLog.filter(
        (obj) =>
          (value == obj.astrologerName &&
            date == moment(obj.timestamp).format("YYYY-MM-DD")) ||
          (value == "all" && date == moment(obj.timestamp).format("YYYY-MM-DD"))
      )
    );
  };

  const getAllLogs = (dateValue) => {
    setIncomingLogFilterList([]);
    axios
      .request({
        method: "POST",
        url: `${BACKEND_API}api/v1/requests/all`,
        headers: {
          userId: localStorage.getItem("userId"),
          token: localStorage.getItem("token"),
        },
        data: {
          date: dateValue,
        },
      })
      .then((response) => {
        setLoading(false);
        setIncomingLog(response.data.data);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getAstrologerList = () => {
    getAstrologers().then((response) => {
      setAstrologerList(response.data.data);
    });
  };

  useEffect(() => {
    const saved = localStorage.getItem("email") || "empty";
    if (saved == "empty") {
      history.pushState("/login");
    } else {
      getAllLogs(date);
      getAstrologerList();
    }
  }, []);

  useEffect(() => {
    if (incomingLog.length > 0) {
      filterLogs(astrologerName);
    }
  }, [incomingLog]);

  useEffect(() => {
    getAllLogs(date);
  }, [date]);

  return (
    <div>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header className="d-sm-flex justify-content-between align-items-center">
                <div>
                  <Card.Title as="h4">Request logs</Card.Title>
                  <p className="card-category">All user's request logs</p>
                </div>
                <div className="my-2">
                  <select
                    onChange={(e) => {
                      setLoading(true);
                      setAstroLogerName(e.target.value);
                      filterLogs(e.target.value);
                      setLoading(false);
                    }}
                    value={astrologerName}
                    className="mr-2 p-1"
                  >
                    <option value="all">All</option>
                    {astrologerList.map((item) => (
                      <option value={item.name}>{item.name}</option>
                    ))}
                  </select>
                  {/* TODO: Add Single Date Picker */}
                  <ReactDatePicker
                    value={date}
                    onChange={(dateValue) => {
                      setDate(moment(dateValue).format("YYYY-MM-DD"));
                    }}
                    placeholderText="YYYY-MM-DD"
                    maxDate={new Date()}
                  />
                </div>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <PaginatedItems
                  itemsPerPage={12}
                  list={incomingLogFilterList}
                  loading={loading}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
