import React, { useState, useEffect, Component } from 'react';
import { useHistory } from 'react-router-dom';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import YouTube from 'react-youtube';
import ReactPaginate from 'react-paginate';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Image from 'react-bootstrap/Image';
import Select from 'react-select';
import languageData from './language.json';

const WEEKDAY = [
  'mondey',
  'tuesday',
  'wednesday',
  'thrusday',
  'friday',
  'saturday',
  'sunday',
];

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Form,
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import { BACKEND_API } from 'webUrls';
import { getAllCategory } from 'api/Category/getAllCategory';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { createAstrologerApi } from 'api/Astrologer';
import { getAstrologerCategories } from 'api/Astrologer';
import { uploadImage } from 'utils/image';
import { getAstrologerById } from 'api/Astrologer';
import { updateAstrologer } from 'api/Astrologer';
import Timeline from './Timeline';
import AppointmentSlots from './AppointmentSlots';
import { uploadSingleImageCustom } from 'utils/image';

export default function CreateForm() {
  const { id } = useParams();
  const [state, setState] = useState({
    consultation_type: 'on-demand',
    profile_pic: '',
  });
  const [categoryList, setCategoryList] = useState([]);
  const [available, setAvailable] = useState({
    0: '',
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
    7: '',
  });
  const [category_mapping, setCategoryMapping] = useState([]);
  const [timeline, setTimeline] = useState(false);
  const [timelineData, setTimelineData] = useState([]);

  const onSubmit = async (e) => {
    try {
      e.preventDefault();

      // let accessUrl = state.profile_pic_file
      //   ? await uploadImage(state.profile_pic_file)
      //   : state.profile_pic;

      if (!id) {
        const response = await createAstrologerApi({
          ...state,
          profile_pic: state.profile_pic,
          mapping: category_mapping,
          timeline: timelineData,
        });
      } else {
        const response = await updateAstrologer({
          ...state,
          profile_pic: state.profile_pic,
          mapping: category_mapping,
          timeline: timelineData,
        });
      }

      history.back();
    } catch (err) {
      console.log(err);
    }
  };

  const onChangeHandler = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const load = async () => {
    try {
      let response = await getAstrologerCategories();
      setCategoryList(response.data.categories);

      if (id) {
        let astrologerResponse = await getAstrologerById({ id });
        if (astrologerResponse.data.data.length == 0) history.back();

        setState(astrologerResponse.data.data.astrologer);
        setTimelineData(astrologerResponse.data.data.timeline_admin);
        let mapping_array = [];
        astrologerResponse.data.data.astrologer.categories.forEach((obj) => {
          mapping_array.push(obj.category_id);
        });
        setCategoryMapping(mapping_array);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onChangeAvailable = (e, index) => {
    setAvailable({
      ...available,
      [index]: { ...available[index], [e.target.name]: e.target.value },
    });
  };

  const onChangeHandlerFile = async (e) => {
    // setState({
    //   ...state,
    //   [e.target.name + "_file"]: e.target.files[0],
    // });

    const data = e.target.files[0];
    const finalImage = await uploadSingleImageCustom(data);
    console.log('finalImage', finalImage);
    setState({
      ...state,
      profile_pic: finalImage,
    });
  };

  const close = () => {
    history.back();
  };

  const handleCategoryClick = (e) => {
    let id = parseInt(e.target.id);

    if (category_mapping.includes(id))
      setCategoryMapping(category_mapping.filter((cid) => cid != id));
    else setCategoryMapping([...category_mapping, id]);
  };

  const onChangeHandlerLanguage = (arr) => {
    setState((prevState) => {
      return {
        ...prevState,
        language: arr.map((obj) => {
          return obj.value;
        }),
      };
    });
  };

  useEffect(() => {
    load();
  }, []);

  return (
    <div>
      <Row>
        <Col md='2'></Col>
        <Col md='8'>
          <Card>
            <Card.Header>
              <Card.Title as='h4'>
                Add Astrologer
                <ReactTooltip anchorId='my-element1' />
                <i
                  id='my-element1'
                  data-tooltip-content='Close Popup'
                  //   onClick={(e) => showForm()}
                  aria-hidden='true'
                  onClick={close}
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    fontWeight: 'bold',
                    float: 'right',
                  }}
                  className='nc-icon nc-simple-remove'
                ></i>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Form onSubmit={onSubmit} encType='multipart/form-data'>
                {(state.profile_pic_file || state.profile_pic !== '') && (
                  <div
                    style={{
                      margin: 'auto',
                      width: 130,
                      height: 130,
                      borderRadius: '50%',
                      overflow: 'hidden',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      src={
                        state.profile_pic_file
                          ? URL.createObjectURL(state.profile_pic_file)
                          : state.profile_pic
                      }
                      style={{ width: '130px', height: 'auto' }}
                    />
                  </div>
                )}
                <Row>
                  <Col className='pr-1'>
                    <Form.Group>
                      <label>Astrologer Type</label>
                      {/* <select
                        value={state.astrologer_category}
                        onChange={onChangeHandler}
                        required
                        name="astrologer_category"
                        className="custom-select"
                      >
                        <option value="">Astrologer Type</option>
                        {categoryList.map((obj, index) => {
                          return (
                            <option key={"category-" + obj.id} value={obj.id}>
                              {obj.name}
                            </option>
                          );
                        })}
                      </select> */}
                      <div className='d-flex justify-content-around flex-wrap w-100  b-solid b-1'>
                        {categoryList.map((obj, index) => {
                          return (
                            <div
                              key={'category-' + obj.id}
                              id={obj.id}
                              onClick={handleCategoryClick}
                              style={{
                                width: 'max-content',
                                padding: '5px 10px',
                                border: 'solid black 1px',
                                background:
                                  category_mapping.includes(obj.id) &&
                                  '#b0aeae',
                              }}
                              className='m-1'
                            >
                              {obj.name}
                            </div>
                          );
                        })}
                      </div>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col className=''>
                    <Form.Group>
                      <label htmlFor='exampleInputEmail1'>Name</label>
                      <input
                        type='text'
                        onChange={onChangeHandler}
                        value={state.name}
                        name='name'
                        required
                        className='form-control'
                        id='name'
                        aria-describedby='coursename'
                        placeholder='Enter Name'
                      />
                    </Form.Group>
                  </Col>
                  <Col className=''>
                    <Form.Group>
                      <label htmlFor='exampleInputEmail1'>Price(per min)</label>
                      <input
                        type='number'
                        name='price'
                        value={state.price}
                        onChange={onChangeHandler}
                        required
                        className='form-control'
                        id='price'
                        min={1}
                        aria-describedby='price'
                        placeholder='price'
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <label htmlFor='description'>Description</label>
                      <textarea
                        name='description'
                        value={state.description}
                        onChange={onChangeHandler}
                        required
                        className='form-control'
                        aria-describedby='description'
                        placeholder='Enter Description'
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Group>
                      <label htmlFor='description'>Astrologer Type</label>
                      <select
                        type='consultation_type'
                        name='consultation_type'
                        value={state.consultation_type}
                        onChange={onChangeHandler}
                        required
                        className='form-control'
                        aria-describedby='consultation_type'
                        placeholder='Enter Astrologer Type'
                      >
                        <option value='on-demand'>ON DEMAND</option>
                        <option value='schedule'>SCHEDULE</option>
                      </select>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Group>
                      <label htmlFor='email'>Language</label>
                      <Select
                        name='language'
                        value={languageData.filter((obj) => {
                          return state?.language?.includes(obj.value);
                        })}
                        options={languageData}
                        isMulti
                        onChange={onChangeHandlerLanguage}
                        required
                        aria-describedby='language'
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col className='pr-1'>
                    <Form.Group>
                      <label htmlFor='email'>Email</label>
                      <input
                        type='email'
                        name='email'
                        value={state.email}
                        onChange={onChangeHandler}
                        required
                        className='form-control'
                        aria-describedby='email'
                        placeholder='Enter Email'
                      />
                    </Form.Group>
                  </Col>
                  {!id ? (
                    <Col className='pl-1'>
                      <Form.Group>
                        <label htmlFor='password'>Password</label>
                        <input
                          type='password'
                          value={state.password}
                          name='password'
                          onChange={onChangeHandler}
                          required
                          className='form-control'
                          id='password'
                          aria-describedby='password'
                          placeholder='Enter Password'
                        />
                      </Form.Group>
                    </Col>
                  ) : null}
                </Row>
                <Row>
                  <Col className='pr-1'>
                    <Form.Group>
                      <label htmlFor='profile_pic'>Profile pic</label>
                      <input
                        type='file'
                        name='profile_pic'
                        accept='image/png, image/gif, image/jpeg'
                        onChange={onChangeHandlerFile}
                        className='form-control'
                        aria-describedby='profile_pic'
                      />
                    </Form.Group>
                  </Col>
                  <Col className='pl-1'>
                    <Form.Group>
                      <label htmlFor='exp'>Exprience</label>
                      <input
                        type='number'
                        name='exp'
                        value={state.exp}
                        onChange={onChangeHandler}
                        required
                        className='form-control'
                        id='exp'
                        aria-describedby='exp'
                        placeholder='Enter exp'
                      />
                    </Form.Group>
                  </Col>
                  <Col className='pl-1'>
                    <Form.Group>
                      <label htmlFor='phone'>Phone Number</label>
                      <input
                        type='text'
                        name='phone'
                        onChange={onChangeHandler}
                        required
                        value={state.phone}
                        maxLength={10}
                        minLength={10}
                        className='form-control'
                        id='phone'
                        aria-describedby='phone'
                        placeholder='Enter phone number'
                      />
                    </Form.Group>
                  </Col>
                </Row>

                {/* {Object.entries(available).map((obj, index) => (
                  <>
                    <p className="p-1 pb-0">{WEEKDAY[index]}</p>
                    <Row>
                      <Col className="p-1 pt-0" md="4">
                        <Form.Group>
                          <input
                            type="date"
                            name="from"
                            className="form-control"
                            onChange={(e) => onChangeAvailable(e, index)}
                          />
                        </Form.Group>
                      </Col>

                      <Col className="p-1" md="4">
                        <Form.Group>
                          <input
                            type="date"
                            name="to"
                            className="form-control"
                            onChange={onChangeAvailable}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </>
                ))} */}

                {id && (
                  <button type='submit' className='btn btn-primary mt-3'>
                    Update
                  </button>
                )}
                {!id && (
                  <button type='submit' className='btn btn-primary mt-3'>
                    Submit
                  </button>
                )}

                <button
                  type='button'
                  className='btn btn-secondary mt-3'
                  onClick={() => setTimeline(true)}
                >
                  Timeline
                </button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
        <Col md='2'></Col>
      </Row>
      {timeline ? (
        state.consultation_type === 'on-demand' ? (
          <Timeline
            id={id}
            timelineData={timelineData}
            setTimelineData={setTimelineData}
            timeline={timeline}
            closePopup={() => setTimeline(false)}
          />
        ) : (
          <AppointmentSlots
            id={id}
            timelineData={timelineData}
            setTimelineData={setTimelineData}
            timeline={timeline}
            closePopup={() => setTimeline(false)}
          />
        )
      ) : null}
    </div>
  );
}
