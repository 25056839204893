import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import { BACKEND_API } from 'webUrls';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import DatePicker from './Astrologer/elements/DatePicker';
import DownloadUserPayments from './ExcelDownloads/DownloadUserPayments';

function Visitor() {
  const queryString = window.location.href;
  var queryString1 = queryString.split('/')[5];

  let itemsPerPage = 30;

  let history = useHistory();
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [value, setValue] = useState([null, null]);
  const [openDownloadPaymentModal, setOpenDownloadPaymentModal] =
    useState(false);

  useEffect(() => {
    const saved = localStorage.getItem('email') || 'empty';
    if (saved == 'empty') {
      history.push('/login');
    } else {
      var payload = new URLSearchParams();
      payload.append('userId', localStorage.getItem('userId'));
      payload.append('id', queryString1);

      fetch(BACKEND_API + 'course/visitors', {
        method: 'POST',
        body: payload,
        rejectUnauthorized: false,
        headers: {
          userId: localStorage.getItem('userId'),
          token: localStorage.getItem('token'),
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status) {
            setPaymentHistory(data.data);
            setFilterList(data.data);
          } else {
            alert(data.message);
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  }, []);

  // Pagination Content
  const [itemOffset, setItemOffset] = useState(0);
  const [pageCount, setPageCount] = useState(
    Math.ceil(filterList.length / itemsPerPage)
  );
  const endOffset = itemOffset + itemsPerPage;
  // console.log(`Loading items from ${itemOffset} to ${endOffset}`);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % filterList.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(0);
    setItemOffset(newOffset);
  };

  useEffect(() => {
    setPageCount(Math.ceil(filterList.length / itemsPerPage));
  }, [filterList]);

  return (
    <>
      <div style={{ position: 'relative' }}>
        <Container fluid>
          <Row>
            <Col md='12'>
              <Card className='strpied-tabled-with-hover'>
                <Card.Header className='d-sm-flex justify-content-between align-items-center'>
                  <div>
                    <Card.Title as='h4'>Visitor List</Card.Title>
                    <p className='card-category'>
                      Here is a List of all Visitor List
                    </p>
                  </div>
                  {/* <DatePicker value={value} setValue={filterByRange} /> */}
                  <div>
                    {/* <button
                      type='button'
                      className='btn btn-warning'
                      onClick={() => setOpenDownloadPaymentModal(true)}
                    >
                      Download Excel
                    </button> */}
                  </div>
                </Card.Header>
                <Card.Body className='table-full-width table-responsive px-0'>
                  <Table className='table-hover table-striped'>
                    <thead>
                      <tr>
                        <th className='border-0'>ID</th>
                        <th className='border-0'>Name</th>
                        <th className='border-0'>Email</th>
                        <th className='border-0'>Phone</th>

                        <th className='border-0'>Registration Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filterList.length > 0 &&
                        filterList.map((user, index) => {
                          if (index >= itemOffset && index < endOffset)
                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{user.name}</td>
                                <td>{user.email}</td>
                                <td>{user.phone}</td>

                                <td>
                                  {moment(user.createdDate).format(
                                    'DD-MM-YYYY HH:mm A'
                                  )}
                                </td>
                              </tr>
                            );
                        })}

                      {filterList.length <= 0 && (
                        <tr>
                          <td colSpan={10} align='center'>
                            No Record Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  {/* <PaginatedItems itemsPerPage={30} list={userList} filterList={filterList} /> */}
                </Card.Body>
              </Card>
              <ReactPaginate
                breakLabel='...'
                nextLabel='next >'
                className='pagination'
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel='< previous'
                renderOnZeroPageCount={null}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Visitor;
