import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { BACKEND_API } from 'webUrls';

export default function Login() {

  let history = useHistory();

  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });

  const onSubmit = async (e) => {
    e.preventDefault();

    var payload = new URLSearchParams();
    payload.append("email", credentials.email);
    payload.append("password", credentials.password);

    await fetch(BACKEND_API + 'course/login', {
      method: 'POST',
      body: payload,
      rejectUnauthorized: false,
      headers: {},
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          localStorage.setItem("userId", data['data'].intId);
          localStorage.setItem("name", data['data'].name);
          localStorage.setItem("email", data['data'].email);
          localStorage.setItem("token", data['data'].token);
          history.push("/admin/userlist");
        } else {
          alert(data.message)
        }
      })
      .catch((err) => {
        console.log(err.message);
      });

  }

  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.id]: e.target.value });
  }

  useEffect(() => {
    document.getElementById("loginform").reset();
    checkIfLoggedIn();
  }, []);

  const checkIfLoggedIn = () => {
    const saved = localStorage.getItem("email") || "empty";
    if (saved != "empty") {
      history.push("/admin/userlist");
    }
  }

  return (
    <div className='container' style={{ padding: "100px" }}>
      {/* <div className='container'>
        <div>
          <h2 style={{ padding: "20px", fontWeight: "500" }}>Login</h2>
        </div>
        <form onSubmit={onSubmit}>
          <div className="form-group">
            <label htmlFor="email">Email address</label>
            <input type="email" required className="form-control" onChange={onChange} id="email" value={credentials.email} aria-describedby="emailHelp" placeholder="Enter email" />
            <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input type="password" required className="form-control" id="password" onChange={onChange} value={credentials.password} placeholder="Password" />
          </div>

          <button type="submit" className="btn btn-primary">Submit</button>
        </form>
      </div> */}




      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-2"></div>
          <div className="col-lg-6 col-md-8 login-box">
            <div className="col-lg-12 login-key">
              <i className="fa fa-key" aria-hidden="true"></i>
            </div>
            <div className="col-lg-12 login-title">
              ADMIN PANEL
            </div>

            <div className="col-lg-12 login-form">
              <div className="col-lg-12 login-form">
                <form id='loginform' onSubmit={onSubmit}>
                  <div className="form-group">
                    <label className="form-control-label">EMAIL</label>
                    <input type="email" required onChange={onChange} id="email" value={credentials.email} className="form-control" />
                  </div>
                  <div className="form-group">
                    <label className="form-control-label">PASSWORD</label>
                    <input type="password" required id="password" onChange={onChange} value={credentials.password} className="form-control" />
                  </div>

                  <div className="col-lg-12 loginbttm">
                    <div className="col-lg-6 login-btm login-text">
                    </div>
                    <div className="col-lg-6 login-btm login-button">
                      <button type="submit" className="btn btn-outline-primary">LOGIN</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-3 col-md-2"></div>
          </div>
        </div>
      </div>









    </div>

  )
}
