import React, { useState, useEffect, Component } from "react";
import { useHistory } from "react-router-dom";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import ReactPaginate from "react-paginate";

// react-bootstrap components
import { Card, Table, Row, Col } from "react-bootstrap";
import { getCampaigns, deleteCampaigns } from "api/Astrologer";
import moment from "moment";

function Items({ currentItems }) {
  const history = useHistory();
  const deleteAction = async (id) => {
    try {
      await deleteCampaigns(id);

      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  const openEdit = (id) => {
    history.push("/admin/campaignForm/" + id);
  };

  return (
    <>
      <Table className="table-hover table-striped">
        <thead>
          <tr>
            <th className="border-0">ID</th>
            <th className="border-0">Title</th>
            <th className="border-0">Amount</th>
            <th className="border-0">StartTime</th>
            <th className="border-0">Action</th>
          </tr>
        </thead>
        <tbody>
          {currentItems &&
            currentItems.map((campagin, index) => (
              <tr key={campagin.id}>
                <td>{index + 1}</td>
                <td>{campagin.title}</td>
                <td>{campagin.amount}</td>
                <td>{moment(campagin.startTime).format("LLL")}</td>
                <td>
                  {" "}
                  <ReactTooltip anchorId="my-element" />
                  <i
                    id="my-element12"
                    data-tooltip-content="Copy Link"
                    aria-hidden="true"
                    style={{ color: "green" }}
                    onClick={() =>
                      navigator.clipboard.writeText(
                        `https://astrosagga.com/join-campaign/${campagin.id}`
                      )
                    }
                    className="fa fa-copy "
                  ></i>
                  <ReactTooltip anchorId="my-element" />
                  <i
                    id="my-element"
                    data-tooltip-content="Edit Course"
                    aria-hidden="true"
                    style={{ color: "orange" }}
                    onClick={() => openEdit(campagin.id)}
                    className="fa fa-edit"
                  ></i>
                  <ReactTooltip anchorId="my-element11" />
                  <i
                    id="my-element11"
                    data-tooltip-content="Delete Course"
                    aria-hidden="true"
                    style={{ color: "red" }}
                    onClick={() => deleteAction(campagin.id)}
                    className="fa fa-trash"
                  ></i>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </>
  );
}

function PaginatedItems({ itemsPerPage, list }) {
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = list.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(list.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % list.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  return (
    <>
      <Items currentItems={currentItems} />
      <ReactPaginate
        breakLabel="..."
        nextLabel="next >"
        className="pagination"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="< previous"
        renderOnZeroPageCount={null}
      />
    </>
  );
}

export default function index() {
  const history = useHistory();
  const [campaigns, setCampaigns] = useState([]);

  const openCreate = () => {
    history.push("/admin/campaignForm");
  };

  const load = async () => {
    try {
      let response = await getCampaigns();
      setCampaigns(response.data.campaigns);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    load();
  }, []);

  return (
    <div>
      {" "}
      <button
        type="button"
        onClick={() => openCreate()}
        className="btn btn-primary"
        style={{ margin: "10px" }}
      >
        Add Campaign
      </button>
      <Row>
        <Col md="12">
          <Card className="strpied-tabled-with-hover">
            <Card.Header>
              <Card.Title as="h4">All Campaign</Card.Title>
              <p className="card-category">Here is a list of all campaigns</p>
            </Card.Header>
            <Card.Body className="table-full-width table-responsive px-0">
              <PaginatedItems itemsPerPage={10} list={campaigns} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
